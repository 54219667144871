.hero.careers{
  .hero-animate{
    background-image: url('../img/careers/hero.jpg');
  }
  @include cover_bg();
  background-position: center bottom;
  @include breakpoint(medium down){
    .job-meta{
      span{
        font-size: 16px;
        padding-right: 10px;
      }
    }
  }
  @include breakpoint(small down){
    background-position: center center;
  }
}
.purple{
  color: $purple !important;
}
.gray-copy{
  color: $gray !important;
}
.wrapper.career{
  padding-left:20px;
  padding-right:20px;
}
.career{
  .category-tags{
    margin-top:50px;
    max-width: 455px;
  }
  .job-category{
    position: relative;
    margin:60px 0px 100px 90px;
    .img{
      position: relative;
    }
    .content-wrapper{
      // margin-left:30px;
      margin-top: 60px;
      @include breakpoint(small down){
      	margin-top:30px;
      }
    }
    .copy{
      color: $gray;
      font-size: 16px;
      @include gotham-book();
      max-width:360px;
    }
    .columns{
      // padding:0px;
    }
    .category-tag{
      padding:8px 50px;
      text-transform: uppercase;
      margin-bottom: 10px;
      display: block;
      position: relative;
      font-size: 14px;
      @include gotham-medium();
      &.overflow{
        z-index: 10;
      }
    }
    .category-tag.orange-bg,
    .category-tag.teal-bg{
      color: #ffffff;
    }
    .category-tag.yellow-bg{
      color: #6B6B6B;
    }
    img{
      width:100%;
      height: auto;
    }
    .overlay{
      padding: 45px;
      background-color: rgba(137,50,168, .94);
      margin:0 auto;
      color: #ffffff;
      .content{
        display: inline-block;
        border-left: 1px solid #ffffff;
        border-right: 1px solid #ffffff;
        padding-left:20px;
        padding-right:20px;
      }
      h3{
        font-weight: bold;
        margin-bottom: 0px;
        font-family: $gotham;
        font-size: 26px;
        text-transform: uppercase;
        letter-spacing: 3px;
      }
      p{
        font-size: 14px;
        margin-bottom: 0;
        line-height: 18px;
        @include gotham-book();
      }
    }
  }
  .job-category:nth-child(even){
    .category-tag:nth-child(odd){

    }
    .category-tag:nth-child(even){
      padding-left:90px;
      margin-left: -47px;
    }
    .overlay{
	  padding-bottom: 22px;
	  @include breakpoint(medium up){
		padding-bottom:100px;
      }
      position: absolute;
      left: 60px;
      bottom:-40px;
      .content{

      }
      h3{

      }
    }
  }
  .job-category:nth-child(odd){
    .content-wrapper{
      margin-left:0px;
    }
    .copy{
      max-width: 430px;
    }
    .category-tags{
      margin-right: 0px;
      margin-left: auto;
      margin-top:80px;
      .category-tag{
        display: table;
        margin-left: auto;
        margin-right: 0px;
        &.overflow{
          display: block;
        }
      }
    }
    .overlay{
      padding-bottom: 22px;
	  @include breakpoint(medium up){
		padding-bottom:70px;
      }
      position: absolute;
      right: 40px;
      bottom: -40px;
    }
    .category-tag:nth-child(odd){
      margin-right:-60px;
    }
  }
  @include breakpoint(medium only){
    .job-category{
      margin: 0;
    }
    .category-tags{
      margin-top: 80px;
    }
    .job-category.right .img{
	  margin-bottom: 40px!important;
	  @include breakpoint(medium up){
      	margin-bottom: 140px;
      }
      margin-top: 50px;
    }
    .job-category.right .columns:first-child{
      padding-left:20px;
    }
    .job-category:nth-child(odd){
	    @include breakpoint(large up) {
			margin-bottom: 230px;
      	}
    }
    .job-category:nth-child(odd) .img{
      margin-top:80px;
    }
    .job-category:nth-child(odd) .copy{
      margin:0 auto;
      display: block;
    }
    .job-category:nth-child(odd) .category-tags{
      margin-left:auto;
      margin-right: auto;
      display: block;
    }
    .job-category .copy{
      max-width: 400px;
    }
  }
  @include breakpoint(medium down){
    .job-category{
	    @include breakpoint(large up) {
			margin: 60px 20px 100px 20px;
      	}
    }
  }
  @include breakpoint(small only){
    .job-category{
      padding-left:10px;
      padding-right:10px;
      margin-left:0 !important;
      margin-right: 0 !important;
    }
    .job-category .overlay h3{
      margin-bottom: 8px;
      font-size: 18px;
    }

    .job-category.right .img{
        // margin-bottom:90px;
        margin-top:40px;
    }
    .job-category .copy{
      font-size: 14px;
      p{
        font-size: 14px;
      }
    }
    .job-category .overlay{
      padding: 30px;
      margin-bottom: 30px;
    }
    .job-category .overlay .content{
      display: block;
      padding-left: 15px;
      padding-right: 15px;
    }
    .job-category .category-tag{
      padding: 15px 20px 11px 20px;
      font-size: 12px;
      margin:0px;
      margin-bottom: 8px;
    }
    .job-category .content-wrapper{
      margin:0px;
      margin-top: 60px;
      @include breakpoint(large up) {
      margin-top:30px;
      }
    }
    .job-category:nth-child(even) .overlay{
     //position: relative;
      //left:0;
     // right:0px;
    }
    .job-category:nth-child(even) .category-tag:nth-child(even){
      padding-left: 30px;
      margin-left: 0px;
    }
  }
}
.copy {
  @include breakpoint(medium down){
	  margin-top: 70px;
	  max-width: none!important;
  }
}
.actions{
  margin-top:50px;
  hr{
    max-width: 250px;
    border-color: $purple;
    border-width: 2px;
    margin: 15px auto 10px 0px;
    display: none;
  }

  a.job-label{
    color: $gray;
  }
  a{
    font-size: 16px;
    color: $gray;
    @include base_transition(color, .3s);
    &:hover{
      color: $purple;
    }
  }
  a.events{
    display: none;
  }
  a.events::before{
    font-family: 'Material-Design-Iconic-Font';
    content: '\f3aa';
    padding-right:20px;
    display: inline-block;
    font-size: 21px;
    color: $purple;
  }
}
.job-count{
  background: $purple;
  display: inline-block;
  margin-right: 10px;
  @include gotham-medium();
  font-size: 12px;
  color: #ffffff;
  text-align: center;
  $cirlce-size: 35px;
  height: $cirlce-size;
  width: $cirlce-size;
  line-height: $cirlce-size;
  border-radius: 50%;
  border: 1px solid $purple;
  position: relative;
  span{
    color: #ffffff;
  }
  &:hover{
    background: none;
    span{
      visibility: hidden;
      &::after{
        content: '\f2fb';
        font-family: 'Material-Design-Iconic-Font';
        color: $purple;
        visibility: visible;
        font-size: 24px;
        position: absolute;
        left:0;
        right:0;
      }
    }
  }
}
#culture{
  min-height: 500px;
  margin-top: 90px;
  padding-left:20px;
  padding-right:20px;
  background-image: url(../img/careers/culture-bg.jpg);
  @include cover_bg();
  background-position: center center;
  .copy-section{
    margin-top: -62px;
    h2{
      color: $purple;
      margin-bottom: 30px;
    }
    p{
      color: $black;
      font-size: 18px;
      @include gotham-book();
    }
  }
  @include breakpoint(medium down){
    .copy-section{
      margin-top: -54px;
    }
  }
  @include breakpoint(small down){
    .copy-section{
      margin-top: -38px;
      h2{
        font-size: 28px;
        margin-bottom: 20px;
      }
      p{
        font-size: 14px;
      }
    }
  }
}
.copy-section{
  h2{
    color: $purple;
    font-size: 46px;
    @include gotham-book();
    margin-bottom: 15px;
  }
  color: $black;
  p{
    color: $black;
  }
  @include breakpoint(medium down){
    h2{
      font-size: 36px;
    }
    p{
      font-size: 16px;
    }
  }
  @include breakpoint(small down){
    h2{
      font-size: 28px;
      line-height: 36px;
    }
    p{
      font-size: 14px;
      line-height: 28px;
    }
  }
}
#culture-grid{
  padding-top:60px;
  padding-bottom: 60px;
  .grid{
    min-height: 380px;
    background-color: $purple;
    margin-bottom: (0.9375rem * 2);
    position: relative;
    padding:40px;
    @include cover_bg();
    background-position: center center;
    .copy{
      color: #ffffff;
      font-size: 16px;
      line-height: 30px;
      @include gotham-book();
    }
  }
  .title{
    display: none;
    margin:0 auto;
    padding:5px 20px;
    border-left:1px solid #ffffff;
    border-right:1px solid #ffffff;
    width: max-content;
    left:0;
    right: 0;
    @include vertical-center();
    h3{
      text-transform: uppercase;
      color: #ffffff;
      margin-bottom: 0px;
      font-size: 24px;
      font-weight: bold;
    }
    p{
      color: #ffffff;
      line-height: 21px;
    }
    @include breakpoint(small down){
      h3{
        font-size: 18px;
      }
      p{
        font-size: 16px;
      }
    }
  }
  @include breakpoint(medium down){
    padding-left:20px;
    padding-right:20px;
  }
  @include breakpoint(small down){
    .grid{
      min-height: 260px;
    }
    .grid .copy{
      font-size: 14px;
      line-height: 26px;
    }
  }
}
#benefits{
  padding-top:180px;
  padding-bottom: 100px;
  padding-left:20px;
  padding-right:20px;
  .benefits{
    margin-left: auto;
    margin-top:auto;
  }
  .benefit{
    //cursor: pointer;
    color: $purple;
    display: block;
    border: none;
    font-weight: bold;
    margin-bottom:60px;
    outline: 0;
    width: max-content;
    text-transform: none;
    margin-top:60px;
    line-height: 38px;
    @include base_transition(text-transform, .6s);
    img{
      margin-right:20px;
      width: 38px;
    }
    //&:hover{
    //  opacity: .7;
    //}
  }
  @include breakpoint(medium down){
    padding-top: 40px;
  }
  @media(max-width:920px){
    .benefits{
      .columns{
        width:100%;
      }
      .benefit{
        margin-bottom: 0px;
        img{
          margin-right:10px;
        }
      }
    }
  }
  @media(max-width:400px){
    .benefit{
      font-size:14px;
      img{
        display: block;
        margin-bottom: 8px;
      }
    }
  }
}
#spotlight{
  background-image: url(../img/careers/jobs-spotlight-bg.jpg);
  @include cover_bg();
  background-position: center center;
  padding:150px 0;
  z-index: -2;
  h2{
    @include gotham-book();
    font-size: 36px;
    text-align: center;
    line-height: 72px;
    span{
      font-weight: bold;
    }
  }
  .top{
    max-width: 90%;
    margin:0 auto;
    .button{
      display: block;
      margin:0 auto;
      max-width: 380px;
      padding: 20px 1em;
      font-size: 18px;
      border-radius: 0;
      @include vertical-center();
      left:0;
      right:0;
    }
  }
  .cta{
    position: relative;
  }
  .featured-jobs{
    margin-top:50px;
    max-width: 1020px;
    width: 100%;
    .title{
      @include outline_title($black);
      cursor:pointer;
    }
    .job{
      position: relative;
      display: block;
      margin-bottom: 40px;
      width:100%;
      max-width: 450px;
      @include base_transition(all, .3s);
      &:hover{
        color: $purple;
      }
      .description{
        display: none;
        position: absolute;
        margin-top:8px;
        padding:20px 30px;
        z-index: 5;
        @include gotham-book();
        background: $yellow;
        color: $black;
        font-size: 14px;
        line-height: 26px;
      }
      &.active{
        .description{
          display: block;
        }
      }
    }
    .job.active::before{
      content: '';
      background: $yellow;
      position: absolute;
      width: 0px;
      height: 36px;
      z-index: -1;
      left: -20px;
    }
    .job.active::before{
      width: 103%;
    }
  }
  @include breakpoint(medium down){
    .featured-jobs{
      margin-top:100px;
      .job{
        margin-left:auto;
        margin-right: auto;
      }
    }
  }
  @include breakpoint(small down){
    .featured-jobs{
      padding-left:10px;
      padding-right:10px;
    }
    .featured-jobs .title h3{
      font-size: 16px;
    }
    .featured-jobs .title p{
      font-size: 13px;
    }
    .featured-jobs .job .description{
      font-size: 12px;
    }
    h2{
      font-size: 24px;
      line-height: 38px;
    }
    .top .button{
      margin-top: 30px;
      font-size: 14px;
    }
  }
}