.no-pad{
	padding:0px;
}
.wrapper{
	padding-top:80px;
	padding-bottom: 80px;
}
.large-row{
	max-width: 80rem;
}
.button{
	display: block;
	border-radius: 5px;
	text-transform: uppercase;
	font-weight: 500;
	max-width: 280px;
	font-family: $gotham;
	letter-spacing: 1px;
	@include base_transition(all, .3s);
	$gradient_start: #8F4DA7;
	$gradien_end: #713D9F;
	background-color: $purple;
  &:hover{
	  background-color: $dark-purple;
  }
  &.outline{
	background-color: transparent;
	border: 1px solid $purple;
	color: $purple;
	&:hover{
		background-color: $purple;
		color: #ffffff;
	}
  }
	&.gradient{
		@include gradient($gradient_start, $gradien_end);
		&:hover{
			background: $dark-purple;
		}
	}
}
.bottom-v{
  bottom:0px;
  position: absolute;
}
.center-h{
  left:0; 
  right:0;
  marign:0 auto;
}
.align-right{
  right:0;
  margin-left:auto;
  margin-right: 0;
}
.typed-cursor{
	color: #ffffff;
	opacity: 1;
	font-size: 1.5rem;
	display: none;
	@include breakpoint(medium only) {
		font-size: 48px;
	}
	@include breakpoint(large up) {
		font-size: 72px;
	}
	/*
	-webkit-animation: blink 0.7s infinite;
	-moz-animation: blink 0.7s infinite;
	animation: blink 0.7s infinite;
	*/
}
header {
	position: absolute;
	z-index: 99;
	top: rem-calc(40); 
	width: 100%;
	img {
		width: rem-calc(140);
	}
}
.nav {
	z-index: 999;
	display: block;
	position: fixed;
	transform: rotate(90deg);
	transform-origin: 0% 0%;
	height: 142px;
	padding-bottom: 39px;
	background: rgba(151, 151, 151, 0.75);
	width: 100vh;
	margin-left: 38px;
	a {
		color: $white;
		font-weight: 500;
		font-size: rem-calc(15);
		//margin: 0 rem-calc(17);
		text-transform: uppercase;
		display: inline-block;
		white-space: nowrap;
		//text-shadow: 1px 1px 3px hsla(0, 0%, 0%, 0.7);
		position: relative;
		transition: all ease 0.3s;
		line-height: 38px;
		float: left;
		width: 25vh;
		text-align: center;
		&:hover {
			.circle {
				opacity: 1;
			}
			span {
				color: $white;
			}
		}
		.circle {
			height: 131px;
			width: 102%;
			position: absolute;
			background: $purple;
			display: block;
			// top: -16px;
			top: 0;
			// border-radius: 100%;
			z-index: 1;
			left: 1px;
			opacity: 0;
			transition: all ease 0.3s;
		}
		span {
			position: relative;
			z-index: 2;
			padding: 0 30px;
		}
		&.active {
			.circle {
				opacity: 1;
			}
			span {
				color: $white;
			}
		}
	}
}
.mobile-nav {
	background: $dark-purple;
	position: sticky;
	top: 0;
	z-index: 9999;
	padding: rem-calc(20) 0 rem-calc(0);
	#menu-trigger {
		float: left;
		color: $white;
		font-size: 25px;
		transition: all ease 0.3s;
		&.rotate {
			transform: rotate(90deg);
		}
	}
	img {
		float: right;
		width: 80px;
	}
	.extended {
		max-height: 0;
		transition: all ease 0.3s;
		padding-top: rem-calc(10);
		overflow: hidden;
		&.active {
			max-height: 1000px;
		}
		a {
			width: 100%;
			transition: all ease 0.3s;
			@include breakpoint(medium only) {
				width: 50%;
			}
			float: left;
			text-align: center;
			display: inline-block;
			color: $white;
			padding: rem-calc(20) 0;
			&:hover {
				opacity: 0.7;
			}
		}
	}
}
.hero-animate{
	position: absolute;
	transition: all .3s ease;
	animation: zoomOutAnimation 5s;
	-webkit-animation: zoomOutAnimation 5s; /* Safari 4+ */
	-moz-animation:    zoomOutAnimation 5s; /* Fx 5+ */
	-o-animation:      zoomOutAnimation 5s; /* Opera 12+ */
	animation:         zoomOutAnimation 5s; /* IE 10+, Fx 29+ */
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	width: 100%;
	height: 100%;

}
.hero{
	height: rem-calc(250);
	@include breakpoint(medium up){
		min-height: 600px;
	}
	position: relative;
	overflow: hidden;
	@include cover_bg();
	top: 0;
	img.logo{
		width: 8.75rem;
		position: absolute;
		top:60px;
		right:70px;
		display: block;
		@include breakpoint(small down){
			width: 120px;
			position: absolute;
			top: 60px;
			right: 30px;
			display: block;
		}
	}
	.content{
		left:0;
		right:0;
		padding-left: 20px;
		padding-right: 20px;
		h1 {
			text-transform: uppercase;
			font-family: $gotham;
			line-height: 85px;
			margin-bottom: 20px;
			font-size: 72px;
			font-weight: bold;
			color: #ffffff;
			&.hero-headline{
				display: inline;
			}
		}
		p{
			color: #ffffff;
			text-transform: uppercase;
			font-size: 15px;
			@include gotham-medium();
		}
	}
	@include breakpoint(medium only){
		.content{
			h1, .typed-cursor{
				font-size: 48px;
				line-height: 68px;
			}
		}
	}
	@include breakpoint(small only){
		.content{
			h1, .typed-cursor{
				font-size: 38px;
				line-height: 52px;
			}
			p{
				font-size: 14px;
				margin-top: 10px;
			}
		}
	}
	@media(max-width:400px){
		.hero-search{
			height: 45px;
			input[type=text]{
				height: 44px;
				margin-top:-2px;
			}
		}
		.content{
			padding-left: 10px;
			padding-right: 10px;
			h1, .typed-cursor{
				font-size: 28px;
				line-height: 38px;
			}
			p{
				font-size: 14px;
				margin-top: 10px;
			}
		}
	}
}

p {
	font-weight: 400;
	letter-spacing: rem-calc(0.75);
	line-height: rem-calc(36);
}
h2 {
	color: $purple;
	font-weight: 200;
	letter-spacing: rem-calc(5.75);
	line-height: 1;
	margin-bottom: rem-calc(40);
}

.wow {
	visibility: hidden;
}
.body-container {
	position: relative;
}
.internal-nav {
	float: right;
	margin-right: rem-calc(60);
	margin-top: rem-calc(40);
	padding-top: rem-calc(20);
	width: rem-calc(200);
	z-index: 9999;
	left: unset!important;
	display: none;
	//top: 550px!important;
	a {
		display: block;
		text-align: left;
		width:100%;
		display: block;
		padding: rem-calc(5) 0 rem-calc(5) rem-calc(45);
		font-size: rem-calc(14);
		color: $copy-gray;
		line-height: rem-calc(26);
		transition: all ease 0.3s;
		&:hover {
			opacity: 0.7;
		}
		span {
			font-weight: 500;
			margin-right: rem-calc(10);
			font-size: rem-calc(15);
			letter-spacing: rem-calc(2.5);
			line-height: rem-calc(15);
		}
		&.active {
			border-bottom: 1px solid $purple;
			color: $purple;
			&:hover {
				opacity: 1;
			}
		}
	}
	.midnightHeader.default {
	  a {
		  color: $copy-gray;
		  &.active {
			border-bottom: 1px solid $purple;
			color: $purple;
		}
	  }
	}
	.midnightHeader.white {
	  a {
		  color: $white;
		  &.active {
			border-bottom: 1px solid $teal;
			color: $teal;
		}
	  }
	}
}

body.is-reveal-open {
	overflow: visible;
}
.reveal-overlay{
	background-color: rgba(0, 0, 0, 0.7);
	overflow-y: hidden; //think about commenting out
}
.reveal.video{
	position: relative;
	padding:0px;
	background: transparent;
	border: none;
	box-shadow: none;
	iframe, video{
		margin-top:30px;
		width:100%;
		height: 100%;
		min-height: 430px;
	}
}
.close-button{
	text-transform: uppercase;
	font-size: 16px;
	color: #ffffff;
	position: absolute;
	right: 0;
	@include base_transition(color, .3s);
	&:hover{
		color: #EEEEEE;
	}
}
.teal-bg{
	background-color: $teal;
}
.yellow-bg{
	background-color: $yellow;
}
.orange-bg{
	background-color: $orange;
}
.tooltip{
	max-width: 400px;
	min-width: 400px;
	border:1px solid $purple;
	border-top: 5px solid $purple;
	background: #ffffff;
	color: $gray;
	padding:20px 30px;
	font-size: 14px;
	&:before{
		width:100%;
		height: 2px;
		background: $purple;
		marign-top:2px;
	}
	&.bottom::before{
		content: '';
		border:none;
	}
}
.hero.terms{
	background-color: darken($dark-purple, 10%);
}
.terms-and-conditions{
	p{
		color: $copy-gray;
		@include gotham-book();
	}
}