footer{
  background: $background-light-gray;
  padding:90px 45px 80px 45px;
  color: #727171;
  font-family: $gotham;
  .center-container{
    position: relative;
    .center{
      position: relative;
      top:0;
      transform: none;
    }
  }
  #footer-logo{
    max-width: 288px;
    @include vertical-center();
  }
  .footer-wrapper{
    padding:0px;
  }
  #locations-row{
    max-width: 430px;
    margin:0 auto;
    display: block;
    position: relative;
  }
  .city{
    color: $purple;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 500;
  }
  .address{
    font-size: 14px;
    &:before{
      content: 'A';
      color: $purple;
      font-size: 14px;
      font-weight: 500;
      padding-right:10px;
    }
    span{
      display: block;
      padding-left:20px;
    }
  }
  .phone{
    font-size: 14px;
    &:before{
      content: 'P';
      color: $purple;
      font-size: 14px;
      font-weight: 500;
      padding-right:10px;
    }
    span{
      display: block;
      padding-left:20px;
    }
  }
  .trademark{
    font-size: 14px;
    margin-top:30px;
    text-align: center;
  }
  .columns{
    position: relative;
  }
  .contact-us{
    a.button{
      max-width: 240px;
      margin:0 auto;
    }
  }
  .top, .bottom{
    .columns{
      padding:0px;
    }
  }
  .top{
    padding-bottom:30px;
  }
  .bottom{
    border-top: 1px solid #CFD1D7;
    padding-top:40px;
    ul{
      margin:0px;
      li{
        list-style: none;
        padding-bottom: 5px;
        font-size: 14px;
        @include gotham-book();
        a{
          color: $gray;
          @include base_transition(color, .3s);
          &:hover{
            color: $purple;
          }
        }
      }
      li.title{
        a{
          color: $purple;
          @include base_transition(color, .3s);
          &:hover{
            color: $dark-purple;
          }
        }
        margin-bottom: 15px;
        text-transform: uppercase;
      }
    }
  }
  .social{
    .content{
      display: inline-block;
      float: right;
    }
    p{
      color: $purple;
      font-size: 14px;
      @include gotham-book();
    }
  }
  @include breakpoint(medium only){
    #footer-logo{
      padding-top:50px;
    }
  }
  @include breakpoint(medium down){
    padding: 20px 20px 70px 20px;
    #footer-logo{
      margin-bottom: 40px;
    }
    .contact-us .content{
      position: relative;
      display: inline-block;
      float: right;
    }
    #locations-row{
      max-width: 430px;
      margin:0;
      display: block;
      position: relative;
    }
    .address,
    .phone,
    .trademark{
      font-size: 13px;
    }
  }
  @media(max-width:920px){
    #footer-logo{
      position: relative;
      top: 0;
      -webkit-transform: none;
      -ms-transform: none;
      transform: none;
    }
  }
  @include breakpoint(small only){
    padding: 80px 20px 30px 20px;
    #footer-logo{
      margin-bottom: 10px;
      position: relative;
      top:0;
      transform: none;
      max-width: 240px;
    }
    #locations-row,
    .contact-us .content{
      display: none;
    }
    .bottom ul li{
      display: none;
      &.title{
        display: block;
      }
    }
  }
}
.social-button{
  $button-size: 32px;
  height:$button-size;
  width: $button-size;
  line-height: $button-size;
  text-align: center;
  border-radius: 50%;
  background-color: #979797;
  display: inline-block;
  margin-left:5px;
  @include base_transition(background-color, .3s);
  &:hover{
    background-color: $purple;
  }
  &:first-child{
    margin-left: 0px;
  }
  i{
    color: $background-light-gray;
  }
}