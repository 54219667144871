.hero.news{
  .hero-animate{
    background-image: url('../img/news/hero.jpg');
  }
  @include cover_bg();
  background-position: right top;
  @include breakpoint(medium down){
    .job-meta{
      span{
        font-size: 16px;
        padding-right: 10px;
      }
    }
  }
  @include breakpoint(small down){
    background-position: center center;
  }

}
.past {
	span {
		opacity: 0.2;
	}
}
.overlay-fade{
  background-position: bottom center;
  background-repeat: repeat-x;
  position: absolute;
  width:100%;
  height:100%;
  &.purple{
    background-image: url('../img/global/purple-overlay.png');
  }
  &.teal{
    background-image: url('../img/global/teal-overlay.png');
  }
}
.posts{
  padding: 60px 20px 120px 20px;
  margin:0 auto;
  .line-image {
	  margin-top: -201px;
	  position: relative;
	  z-index: 999;
  }
}
.post{
  width:100%;
  min-height: 350px;
  position: relative;
  background-color: $purple;
  margin-bottom: 15px;
  &.event {
	  background: $teal;
  }
  &.tall{
    min-height: 400px;
  }
  //&.img {
	//  /* Rectangle 12 Copy: */
	//  background-image: linear-gradient(0deg, #471B4E 10%, rgba(130,65,153,0.00) 89%);
  //}
}
.preview{
  position: absolute;
  height: 100%;
  width: 100%;
  $gradient_start: #8F4DA7;
  $gradien_end: #713D9F;
  @include gradient($gradient_start, $gradien_end);
  display: none;
  p{
    color: #ffffff;
    padding: 30px;
  }
  &.teal{
    $gradient_start: $teal;
    $gradien_end: darken($teal, 15%);
    @include gradient($gradient_start, $gradien_end);
  }
}
.post-meta{
  position: absolute;
  bottom:0px;
  color: #ffffff;
  left:0;
  right:0;
  max-width: 80%;
  margin:0 auto 25px auto;
  border-left:1px solid #ffffff;
  padding-left: 20px;
  padding-top: 15px;
  .title{
    font-size: 18px;
    line-height: 28px;
    text-transform: uppercase;
    margin-bottom: 5px;
    @include gotham-medium();
  }
  .date{
    margin-bottom: 0px;
  }
}
a.expand{
  background-color: #ffffff;
  display: block;
  position: relative;
  width: 100%;
  border: 1px solid #824199;
  outline:0;
  height: 50px;
  line-height: 50px;
  padding-left: 30px;
  text-transform: uppercase;
  margin-bottom: 25px;
  margin-top: -30px;
  @include gotham-medium();
  &.read-more{
    &::after{
      font-family: 'Material-Design-Iconic-Font';
      content: '\f2f1';
      position: absolute;
      font-size: 24px;
      right:30px;
    }
    &:hover{
      background: $dark-purple;
      color: #ffffff;
    }
  }
  &.watch{
    &::after{
      font-family: 'Material-Design-Iconic-Font';
      content: '\f3aa';
      position: absolute;
      font-size: 24px;
      right:30px;
    }
  }

  &.teal{
    color: $teal;
    border: 1px solid $teal;
    &:hover{
      background: darken($teal, 15%);
    }
  }
}
.button-row{
  .button{
    max-width: 100%;
    margin-top:60px;
    font-size: 15px;
    height: 50px;
    padding: 18px 1em;
    @include gotham-medium();
  }
}
.hero-search{
  border:2px solid #ffffff;
  border-radius: 5px;
  height: 51px;
  width: 580px;
  input[type=text]{
    background: none;
    color: #ffffff;
    font-size: 14px;
    height: 50px;
    border: none;
    width: 240px;
    box-shadow: none;
    @include gotham-book();
  }
  .input-group-label{
    background: none;
    border: none;
    i{
      color: #ffffff;
    }
  }
  button, input[type=submit]{
    width: 100%;
    max-width: 100%;
  }
  .input-group-button{
    width: 289px;
    button, input[type=submit]{
      border: none;
      box-shadow: none;
      border-radius-top-right: 5px;
      border-radius-bottom-right: 5px;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
  }
  @include breakpoint(medium down){
    width: 480px;
    .input-group-button{
      width: 190px;
      button, input[type=submit]{
        font-size: 14px;
      }
    }
  }
  @include breakpoint(small down){
    max-width: 300px;
    .ph-label, input[type=text]{
      width: 100%;
    }
    .input-group-button{
      display: none;
    }
  }
  @media(max-width:400px){
    max-width: 280px;
  }
}
.article{
  border-top:2px solid $gray-medium;
  border-bottom:2px solid $gray-medium;
  margin-bottom: 30px;
  padding-top:40px;
  padding-bottom: 40px;
  color: $purple;
  position: relative;
  display: none;
  .article-meta{
    max-width: 200px;
    span{
      display: block;
      padding-bottom: 8px;
      border-bottom:1px solid $purple;
      @include gotham-book();
      font-size: 14px;
      margin-bottom: 10px;
    }
    .tag::before{
      font-family: 'Material-Design-Iconic-Font';
      content: '\f1ab';
      padding-right:8px;
      font-size: 16px;
    }
    .article-tag::before{
      font-family: 'Material-Design-Iconic-Font';
      content: '\f158';
      padding-right:8px;
      font-size: 16px;
    }
    .date::before{
      font-family: 'Material-Design-Iconic-Font';
      content: '\f332';
      padding-right:8px;
      font-size: 16px;
    }
  }
  .title{
    color: $purple;
    font-size: 24px;
    text-transform: uppercase;
    @include gotham-medium();
    margin-bottom: 40px;
    margin-right: 50px;
    @include breakpoint(medium down){
      margin-right: 0px;
    }
  }
  .content{
    color: $copy-gray;
    font-size: 18px;
    img{
      max-width: 100%;
      width:100%;
      height: auto;
      margin:20px 0;
    }
    p{
      font-size: 18px;
      color: $copy-gray;
    }
  }
  .article-close::after{
    font-family: 'Material-Design-Iconic-Font';
    position: absolute;
    content: '\f135';
    color: $purple;
    font-size: 32px;
    right:20px;
    top: 32px;
  }
  .social-share{
    margin-top: 20px;
    a.social-button{
      background-color: $purple;
      width: 28px;
      height: 28px;
      line-height: 28px;
      font-size: 14px;
      margin-right: 3px;
      &:hover{
        background-color: $dark-purple;
      }
    }
  }
  @include breakpoint(medium down){
    .content{
      p{
        font-size: 16px;
      }
    }
  }
  @include breakpoint(small down){
    padding-top: 20px;
    .content{
      p{
        font-size: 14px;
        line-height: 28px;
      }
    }
    .article-close::after{
      display: block;
      position: relative;
      margin-bottom: 50px;
      float: right;
      clear: both;
    }
    .article-meta{
      max-width: 100%;
      margin-bottom: 40px;
    }
    .title{
      font-size: 21px;
      margin-bottom: 30px;
    }
  }
}
.campus-event{
  margin-bottom:20px;
}