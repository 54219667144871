.home-slider {
	.slide-one, .slide-two, .slide-three, .slide-four {
		height: 650px;
		
		overflow: hidden;
		@include breakpoint(medium up) { 
			height: 800px;
		}
	}
	.slide-one {
		background: url('../img/home/home-slides-1-2200-over20-tiny.jpg');
		background-size: cover;
		background-position: bottom center;
	}
	.slide-two {
		background: url('../img/home/home-slides-2-2200-over20-tiny.jpg');
		background-size: cover;
		background-position: center center;
	}
	.slide-three {
		background: url('../img/home/home-slides-3-2200-over20-tiny.jpg');
		background-size: cover;
		background-position: center center;
	}
	.slide-four {
		background: url('../img/home/home-slides-4-2200-over20-tiny.jpg');
		background-size: cover;
		background-position: center center;
	}
	/*
	#type-container {
		height: rem-calc(200);
	} 
	*/
	.line {
		width: rem-calc(2);
		background: $gray-light;
		&.short {
			height: rem-calc(20);
			@include breakpoint(medium up) { 
				height: rem-calc(80);
			}
		}
		&.long {
			height: rem-calc(40);
			@include breakpoint(medium up) {
				height: rem-calc(140);
			}
		}
	}
	.top-container {
		height: 250px;
		@include breakpoint(medium up) {
			height: rem-calc(500);
		}
		p {
			color: $white;
			text-transform: uppercase;
			font-weight: 500;
			font-size: rem-calc(15);
			letter-spacing: rem-calc(2.5);
			margin-left: rem-calc(-40);
			margin-top: rem-calc(10);
		}
		.center {
			width: 100%;
		}
	}
	.bottom-container {
		height: rem-calc(400);
		padding: 0 rem-calc(20);
		@include breakpoint(medium up) {
			height: rem-calc(300);
		}
		background: rgba(238,238,238, 0.85);
		a {
			@include vertical-center();
			transition: all ease 0.3s;
			&:hover {
				opacity: 0.7;
			}
		}
		i {
			font-size: rem-calc(35);
			color: $white;
		}
		p {
			margin-bottom: 0;
			position: relative;
			@include breakpoint(small only) {
				line-height: 28px;	
			}
		}
		img {
		    position: absolute;
		    right: 6%;
		    bottom: -120px;
		    opacity: 0.5;
		}
		.row {
			position: relative;
		}
	}
	h1 {
		color: $white;
		white-space: normal;
		@include breakpoint(medium only) {
			line-height: 63px;	
		}
		@include breakpoint(large up) {
			font-size: rem-calc(60);
			line-height: rem-calc(85);
			margin-left: rem-calc(-40);
			white-space: pre;
		}
		font-weight: 700;
		letter-spacing: rem-calc(6);
		text-shadow: 0 2px 4px rgba(0,0,0,0.50);
		text-transform: uppercase;
		display: inline;
		vertical-align: top;
		span {
			background-image: linear-gradient(-208deg, #824199 23%, rgba(255, 255, 255, 0) 147%);
			padding: 5px 20px 0 0;
			@include breakpoint(large up) {
				margin-left: -2.5rem;
				padding: 5px 20px 15px;
			}
		}
	}
}

.slick-dots {
	margin: 0;
	list-style: none;
	text-align: center;
	display: block;
    margin-top: -52px;
    position: relative;
	li {
		display: inline;
		margin: 0 5px;
		button {
			color: $copy-gray;
			cursor: pointer;
		}
	}
	.slick-active button {
		color: $purple;
	}
}

.center-container {
	position: relative;
	.center {
		@include vertical-center();
		 width: 100%;
	}
}

@include breakpoint(medium down){
	.bottom-container.center-container{
		.center{
			position: relative;
			top: 0;
			-webkit-transform: none;
			-ms-transform: none;
			transform: none;
			p{
				font-size: 14px;
				padding-top:30px;
			}
		}
	}
}
