@charset "UTF-8";
.gotham-extra-light {
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-style: normal;
  font-weight: 200; }

.gotham-extra-light-italic {
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-style: italic;
  font-weight: 200; }

.gotham-light {
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-style: normal;
  font-weight: 300; }

.gotham-light-italic {
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-style: italic;
  font-weight: 300; }

.gotham-book {
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-style: normal;
  font-weight: 400; }

.gotham-book-italic {
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-style: italic;
  font-weight: 400; }

.gotham-medium {
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-style: normal;
  font-weight: 500; }

.gotham-medium-italic {
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-style: italic;
  font-weight: 500; }

.gotham-bold {
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-style: normal;
  font-weight: 700; }

.gotham-italic {
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-style: italic;
  font-weight: 700; }

.gotham-black {
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-style: normal;
  font-weight: 800; }

.gotham-black {
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-style: italic;
  font-weight: 800; }

p {
  font-weight: 400; }

@keyframes blink {
  50% {
    opacity: 0.0; } }

@-webkit-keyframes blink {
  50% {
    opacity: 0.0; } }

@keyframes zoomOutAnimation {
  0% {
    transform: scale(1.2); }
  100% {
    transform: scale(1); } }

@-webkit-keyframes NAME-YOUR-ANIMATION {
  0% {
    transform: scale(1.2); }
  100% {
    transform: scale(1); } }

@-moz-keyframes NAME-YOUR-ANIMATION {
  0% {
    transform: scale(1.2); }
  100% {
    transform: scale(1); } }

@-o-keyframes NAME-YOUR-ANIMATION {
  0% {
    transform: scale(1.2); }
  100% {
    transform: scale(1); } }

/**
 * Foundation for Sites by ZURB
 * Version 6.4.3
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
@media print, screen and (min-width: 40em) {
  .reveal, .reveal.tiny, .reveal.small, .reveal.large {
    right: auto;
    left: auto;
    margin: 0 auto; } }

/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

.foundation-mq {
  font-family: "small=0em&medium=40em&large=64em&xlarge=75em&xxlarge=90em"; }

html {
  box-sizing: border-box;
  font-size: 100%; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background: #FFFFFF;
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-weight: normal;
  line-height: 1.5;
  color: #3A3A3A;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0; }

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0; }

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1;
  cursor: auto; }
  [data-whatinput='mouse'] button {
    outline: 0; }

pre {
  overflow: auto; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; }

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

.row {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto; }
  .row::before, .row::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .row::after {
    clear: both; }
  .row.collapse > .column, .row.collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .row .row {
    margin-right: -0.625rem;
    margin-left: -0.625rem; }
    @media print, screen and (min-width: 40em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    @media print, screen and (min-width: 64em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    .row .row.collapse {
      margin-right: 0;
      margin-left: 0; }
  .row.expanded {
    max-width: none; }
    .row.expanded .row {
      margin-right: auto;
      margin-left: auto; }
  .row:not(.expanded) .row {
    max-width: none; }
  .row.gutter-small > .column, .row.gutter-small > .columns {
    padding-right: 0.625rem;
    padding-left: 0.625rem; }
  .row.gutter-medium > .column, .row.gutter-medium > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }

.column, .columns {
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .column, .columns {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  .column:last-child:not(:first-child), .columns:last-child:not(:first-child) {
    float: right; }
  .column.end:last-child:last-child, .end.columns:last-child:last-child {
    float: left; }

.column.row.row, .row.row.columns {
  float: none; }

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0; }

.small-1 {
  width: 8.33333%; }

.small-push-1 {
  position: relative;
  left: 8.33333%; }

.small-pull-1 {
  position: relative;
  left: -8.33333%; }

.small-offset-0 {
  margin-left: 0%; }

.small-2 {
  width: 16.66667%; }

.small-push-2 {
  position: relative;
  left: 16.66667%; }

.small-pull-2 {
  position: relative;
  left: -16.66667%; }

.small-offset-1 {
  margin-left: 8.33333%; }

.small-3 {
  width: 25%; }

.small-push-3 {
  position: relative;
  left: 25%; }

.small-pull-3 {
  position: relative;
  left: -25%; }

.small-offset-2 {
  margin-left: 16.66667%; }

.small-4 {
  width: 33.33333%; }

.small-push-4 {
  position: relative;
  left: 33.33333%; }

.small-pull-4 {
  position: relative;
  left: -33.33333%; }

.small-offset-3 {
  margin-left: 25%; }

.small-5 {
  width: 41.66667%; }

.small-push-5 {
  position: relative;
  left: 41.66667%; }

.small-pull-5 {
  position: relative;
  left: -41.66667%; }

.small-offset-4 {
  margin-left: 33.33333%; }

.small-6 {
  width: 50%; }

.small-push-6 {
  position: relative;
  left: 50%; }

.small-pull-6 {
  position: relative;
  left: -50%; }

.small-offset-5 {
  margin-left: 41.66667%; }

.small-7 {
  width: 58.33333%; }

.small-push-7 {
  position: relative;
  left: 58.33333%; }

.small-pull-7 {
  position: relative;
  left: -58.33333%; }

.small-offset-6 {
  margin-left: 50%; }

.small-8 {
  width: 66.66667%; }

.small-push-8 {
  position: relative;
  left: 66.66667%; }

.small-pull-8 {
  position: relative;
  left: -66.66667%; }

.small-offset-7 {
  margin-left: 58.33333%; }

.small-9 {
  width: 75%; }

.small-push-9 {
  position: relative;
  left: 75%; }

.small-pull-9 {
  position: relative;
  left: -75%; }

.small-offset-8 {
  margin-left: 66.66667%; }

.small-10 {
  width: 83.33333%; }

.small-push-10 {
  position: relative;
  left: 83.33333%; }

.small-pull-10 {
  position: relative;
  left: -83.33333%; }

.small-offset-9 {
  margin-left: 75%; }

.small-11 {
  width: 91.66667%; }

.small-push-11 {
  position: relative;
  left: 91.66667%; }

.small-pull-11 {
  position: relative;
  left: -91.66667%; }

.small-offset-10 {
  margin-left: 83.33333%; }

.small-12 {
  width: 100%; }

.small-offset-11 {
  margin-left: 91.66667%; }

.small-up-1 > .column, .small-up-1 > .columns {
  float: left;
  width: 100%; }
  .small-up-1 > .column:nth-of-type(1n), .small-up-1 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-1 > .column:nth-of-type(1n+1), .small-up-1 > .columns:nth-of-type(1n+1) {
    clear: both; }
  .small-up-1 > .column:last-child, .small-up-1 > .columns:last-child {
    float: left; }

.small-up-2 > .column, .small-up-2 > .columns {
  float: left;
  width: 50%; }
  .small-up-2 > .column:nth-of-type(1n), .small-up-2 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-2 > .column:nth-of-type(2n+1), .small-up-2 > .columns:nth-of-type(2n+1) {
    clear: both; }
  .small-up-2 > .column:last-child, .small-up-2 > .columns:last-child {
    float: left; }

.small-up-3 > .column, .small-up-3 > .columns {
  float: left;
  width: 33.33333%; }
  .small-up-3 > .column:nth-of-type(1n), .small-up-3 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-3 > .column:nth-of-type(3n+1), .small-up-3 > .columns:nth-of-type(3n+1) {
    clear: both; }
  .small-up-3 > .column:last-child, .small-up-3 > .columns:last-child {
    float: left; }

.small-up-4 > .column, .small-up-4 > .columns {
  float: left;
  width: 25%; }
  .small-up-4 > .column:nth-of-type(1n), .small-up-4 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-4 > .column:nth-of-type(4n+1), .small-up-4 > .columns:nth-of-type(4n+1) {
    clear: both; }
  .small-up-4 > .column:last-child, .small-up-4 > .columns:last-child {
    float: left; }

.small-up-5 > .column, .small-up-5 > .columns {
  float: left;
  width: 20%; }
  .small-up-5 > .column:nth-of-type(1n), .small-up-5 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-5 > .column:nth-of-type(5n+1), .small-up-5 > .columns:nth-of-type(5n+1) {
    clear: both; }
  .small-up-5 > .column:last-child, .small-up-5 > .columns:last-child {
    float: left; }

.small-up-6 > .column, .small-up-6 > .columns {
  float: left;
  width: 16.66667%; }
  .small-up-6 > .column:nth-of-type(1n), .small-up-6 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-6 > .column:nth-of-type(6n+1), .small-up-6 > .columns:nth-of-type(6n+1) {
    clear: both; }
  .small-up-6 > .column:last-child, .small-up-6 > .columns:last-child {
    float: left; }

.small-up-7 > .column, .small-up-7 > .columns {
  float: left;
  width: 14.28571%; }
  .small-up-7 > .column:nth-of-type(1n), .small-up-7 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-7 > .column:nth-of-type(7n+1), .small-up-7 > .columns:nth-of-type(7n+1) {
    clear: both; }
  .small-up-7 > .column:last-child, .small-up-7 > .columns:last-child {
    float: left; }

.small-up-8 > .column, .small-up-8 > .columns {
  float: left;
  width: 12.5%; }
  .small-up-8 > .column:nth-of-type(1n), .small-up-8 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-8 > .column:nth-of-type(8n+1), .small-up-8 > .columns:nth-of-type(8n+1) {
    clear: both; }
  .small-up-8 > .column:last-child, .small-up-8 > .columns:last-child {
    float: left; }

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0; }

.small-collapse .row {
  margin-right: 0;
  margin-left: 0; }

.expanded.row .small-collapse.row {
  margin-right: 0;
  margin-left: 0; }

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 0.625rem;
  padding-left: 0.625rem; }

.small-centered {
  margin-right: auto;
  margin-left: auto; }
  .small-centered, .small-centered:last-child:not(:first-child) {
    float: none;
    clear: both; }

.small-uncentered,
.small-push-0,
.small-pull-0 {
  position: static;
  float: left;
  margin-right: 0;
  margin-left: 0; }

@media print, screen and (min-width: 40em) {
  .medium-1 {
    width: 8.33333%; }
  .medium-push-1 {
    position: relative;
    left: 8.33333%; }
  .medium-pull-1 {
    position: relative;
    left: -8.33333%; }
  .medium-offset-0 {
    margin-left: 0%; }
  .medium-2 {
    width: 16.66667%; }
  .medium-push-2 {
    position: relative;
    left: 16.66667%; }
  .medium-pull-2 {
    position: relative;
    left: -16.66667%; }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .medium-3 {
    width: 25%; }
  .medium-push-3 {
    position: relative;
    left: 25%; }
  .medium-pull-3 {
    position: relative;
    left: -25%; }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .medium-4 {
    width: 33.33333%; }
  .medium-push-4 {
    position: relative;
    left: 33.33333%; }
  .medium-pull-4 {
    position: relative;
    left: -33.33333%; }
  .medium-offset-3 {
    margin-left: 25%; }
  .medium-5 {
    width: 41.66667%; }
  .medium-push-5 {
    position: relative;
    left: 41.66667%; }
  .medium-pull-5 {
    position: relative;
    left: -41.66667%; }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .medium-6 {
    width: 50%; }
  .medium-push-6 {
    position: relative;
    left: 50%; }
  .medium-pull-6 {
    position: relative;
    left: -50%; }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .medium-7 {
    width: 58.33333%; }
  .medium-push-7 {
    position: relative;
    left: 58.33333%; }
  .medium-pull-7 {
    position: relative;
    left: -58.33333%; }
  .medium-offset-6 {
    margin-left: 50%; }
  .medium-8 {
    width: 66.66667%; }
  .medium-push-8 {
    position: relative;
    left: 66.66667%; }
  .medium-pull-8 {
    position: relative;
    left: -66.66667%; }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .medium-9 {
    width: 75%; }
  .medium-push-9 {
    position: relative;
    left: 75%; }
  .medium-pull-9 {
    position: relative;
    left: -75%; }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .medium-10 {
    width: 83.33333%; }
  .medium-push-10 {
    position: relative;
    left: 83.33333%; }
  .medium-pull-10 {
    position: relative;
    left: -83.33333%; }
  .medium-offset-9 {
    margin-left: 75%; }
  .medium-11 {
    width: 91.66667%; }
  .medium-push-11 {
    position: relative;
    left: 91.66667%; }
  .medium-pull-11 {
    position: relative;
    left: -91.66667%; }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .medium-12 {
    width: 100%; }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .medium-up-1 > .column, .medium-up-1 > .columns {
    float: left;
    width: 100%; }
    .medium-up-1 > .column:nth-of-type(1n), .medium-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-1 > .column:nth-of-type(1n+1), .medium-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .medium-up-1 > .column:last-child, .medium-up-1 > .columns:last-child {
      float: left; }
  .medium-up-2 > .column, .medium-up-2 > .columns {
    float: left;
    width: 50%; }
    .medium-up-2 > .column:nth-of-type(1n), .medium-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-2 > .column:nth-of-type(2n+1), .medium-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .medium-up-2 > .column:last-child, .medium-up-2 > .columns:last-child {
      float: left; }
  .medium-up-3 > .column, .medium-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .medium-up-3 > .column:nth-of-type(1n), .medium-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-3 > .column:nth-of-type(3n+1), .medium-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .medium-up-3 > .column:last-child, .medium-up-3 > .columns:last-child {
      float: left; }
  .medium-up-4 > .column, .medium-up-4 > .columns {
    float: left;
    width: 25%; }
    .medium-up-4 > .column:nth-of-type(1n), .medium-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-4 > .column:nth-of-type(4n+1), .medium-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .medium-up-4 > .column:last-child, .medium-up-4 > .columns:last-child {
      float: left; }
  .medium-up-5 > .column, .medium-up-5 > .columns {
    float: left;
    width: 20%; }
    .medium-up-5 > .column:nth-of-type(1n), .medium-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-5 > .column:nth-of-type(5n+1), .medium-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .medium-up-5 > .column:last-child, .medium-up-5 > .columns:last-child {
      float: left; }
  .medium-up-6 > .column, .medium-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .medium-up-6 > .column:nth-of-type(1n), .medium-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-6 > .column:nth-of-type(6n+1), .medium-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .medium-up-6 > .column:last-child, .medium-up-6 > .columns:last-child {
      float: left; }
  .medium-up-7 > .column, .medium-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .medium-up-7 > .column:nth-of-type(1n), .medium-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-7 > .column:nth-of-type(7n+1), .medium-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .medium-up-7 > .column:last-child, .medium-up-7 > .columns:last-child {
      float: left; }
  .medium-up-8 > .column, .medium-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .medium-up-8 > .column:nth-of-type(1n), .medium-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-8 > .column:nth-of-type(8n+1), .medium-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .medium-up-8 > .column:last-child, .medium-up-8 > .columns:last-child {
      float: left; }
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .medium-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .medium-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }
  .medium-centered {
    margin-right: auto;
    margin-left: auto; }
    .medium-centered, .medium-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .medium-uncentered,
  .medium-push-0,
  .medium-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

@media print, screen and (min-width: 64em) {
  .large-1 {
    width: 8.33333%; }
  .large-push-1 {
    position: relative;
    left: 8.33333%; }
  .large-pull-1 {
    position: relative;
    left: -8.33333%; }
  .large-offset-0 {
    margin-left: 0%; }
  .large-2 {
    width: 16.66667%; }
  .large-push-2 {
    position: relative;
    left: 16.66667%; }
  .large-pull-2 {
    position: relative;
    left: -16.66667%; }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .large-3 {
    width: 25%; }
  .large-push-3 {
    position: relative;
    left: 25%; }
  .large-pull-3 {
    position: relative;
    left: -25%; }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .large-4 {
    width: 33.33333%; }
  .large-push-4 {
    position: relative;
    left: 33.33333%; }
  .large-pull-4 {
    position: relative;
    left: -33.33333%; }
  .large-offset-3 {
    margin-left: 25%; }
  .large-5 {
    width: 41.66667%; }
  .large-push-5 {
    position: relative;
    left: 41.66667%; }
  .large-pull-5 {
    position: relative;
    left: -41.66667%; }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .large-6 {
    width: 50%; }
  .large-push-6 {
    position: relative;
    left: 50%; }
  .large-pull-6 {
    position: relative;
    left: -50%; }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .large-7 {
    width: 58.33333%; }
  .large-push-7 {
    position: relative;
    left: 58.33333%; }
  .large-pull-7 {
    position: relative;
    left: -58.33333%; }
  .large-offset-6 {
    margin-left: 50%; }
  .large-8 {
    width: 66.66667%; }
  .large-push-8 {
    position: relative;
    left: 66.66667%; }
  .large-pull-8 {
    position: relative;
    left: -66.66667%; }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .large-9 {
    width: 75%; }
  .large-push-9 {
    position: relative;
    left: 75%; }
  .large-pull-9 {
    position: relative;
    left: -75%; }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .large-10 {
    width: 83.33333%; }
  .large-push-10 {
    position: relative;
    left: 83.33333%; }
  .large-pull-10 {
    position: relative;
    left: -83.33333%; }
  .large-offset-9 {
    margin-left: 75%; }
  .large-11 {
    width: 91.66667%; }
  .large-push-11 {
    position: relative;
    left: 91.66667%; }
  .large-pull-11 {
    position: relative;
    left: -91.66667%; }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .large-12 {
    width: 100%; }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .large-up-1 > .column, .large-up-1 > .columns {
    float: left;
    width: 100%; }
    .large-up-1 > .column:nth-of-type(1n), .large-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-1 > .column:nth-of-type(1n+1), .large-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .large-up-1 > .column:last-child, .large-up-1 > .columns:last-child {
      float: left; }
  .large-up-2 > .column, .large-up-2 > .columns {
    float: left;
    width: 50%; }
    .large-up-2 > .column:nth-of-type(1n), .large-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-2 > .column:nth-of-type(2n+1), .large-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .large-up-2 > .column:last-child, .large-up-2 > .columns:last-child {
      float: left; }
  .large-up-3 > .column, .large-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .large-up-3 > .column:nth-of-type(1n), .large-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-3 > .column:nth-of-type(3n+1), .large-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .large-up-3 > .column:last-child, .large-up-3 > .columns:last-child {
      float: left; }
  .large-up-4 > .column, .large-up-4 > .columns {
    float: left;
    width: 25%; }
    .large-up-4 > .column:nth-of-type(1n), .large-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-4 > .column:nth-of-type(4n+1), .large-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .large-up-4 > .column:last-child, .large-up-4 > .columns:last-child {
      float: left; }
  .large-up-5 > .column, .large-up-5 > .columns {
    float: left;
    width: 20%; }
    .large-up-5 > .column:nth-of-type(1n), .large-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-5 > .column:nth-of-type(5n+1), .large-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .large-up-5 > .column:last-child, .large-up-5 > .columns:last-child {
      float: left; }
  .large-up-6 > .column, .large-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .large-up-6 > .column:nth-of-type(1n), .large-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-6 > .column:nth-of-type(6n+1), .large-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .large-up-6 > .column:last-child, .large-up-6 > .columns:last-child {
      float: left; }
  .large-up-7 > .column, .large-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .large-up-7 > .column:nth-of-type(1n), .large-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-7 > .column:nth-of-type(7n+1), .large-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .large-up-7 > .column:last-child, .large-up-7 > .columns:last-child {
      float: left; }
  .large-up-8 > .column, .large-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .large-up-8 > .column:nth-of-type(1n), .large-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-8 > .column:nth-of-type(8n+1), .large-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .large-up-8 > .column:last-child, .large-up-8 > .columns:last-child {
      float: left; }
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .large-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .large-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }
  .large-centered {
    margin-right: auto;
    margin-left: auto; }
    .large-centered, .large-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .large-uncentered,
  .large-push-0,
  .large-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

.column-block {
  margin-bottom: 1.25rem; }
  .column-block > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .column-block {
      margin-bottom: 1.875rem; }
      .column-block > :last-child {
        margin-bottom: 0; } }

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0; }

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 2.25rem;
  text-rendering: optimizeLegibility; }

em,
i {
  font-style: italic;
  line-height: inherit; }

strong,
b {
  font-weight: bold;
  line-height: inherit; }

small {
  font-size: 80%;
  line-height: inherit; }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  color: inherit;
  text-rendering: optimizeLegibility; }
  h1 small, .h1 small,
  h2 small, .h2 small,
  h3 small, .h3 small,
  h4 small, .h4 small,
  h5 small, .h5 small,
  h6 small, .h6 small {
    line-height: 0;
    color: #cacaca; }

h1, .h1 {
  font-size: 1.5rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h2, .h2 {
  font-size: 1.25rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h3, .h3 {
  font-size: 1.1875rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h4, .h4 {
  font-size: 1.125rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h5, .h5 {
  font-size: 1.0625rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h6, .h6 {
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

@media print, screen and (min-width: 40em) {
  h1, .h1 {
    font-size: 3rem; }
  h2, .h2 {
    font-size: 2.5rem; }
  h3, .h3 {
    font-size: 1.9375rem; }
  h4, .h4 {
    font-size: 1.5625rem; }
  h5, .h5 {
    font-size: 1.25rem; }
  h6, .h6 {
    font-size: 1rem; } }

a {
  line-height: inherit;
  color: #824199;
  text-decoration: none;
  cursor: pointer; }
  a:hover, a:focus {
    color: #703884; }
  a img {
    border: 0; }

hr {
  clear: both;
  max-width: 75rem;
  height: 0;
  margin: 1.25rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #cacaca;
  border-left: 0; }

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 2.25rem; }

li {
  font-size: inherit; }

ul {
  margin-left: 1.25rem;
  list-style-type: disc; }

ol {
  margin-left: 1.25rem; }

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0; }

dl {
  margin-bottom: 1rem; }
  dl dt {
    margin-bottom: 0.3rem;
    font-weight: bold; }

blockquote {
  margin: 0 0 1rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #cacaca; }
  blockquote, blockquote p {
    line-height: 2.25rem;
    color: #727171; }

cite {
  display: block;
  font-size: 0.8125rem;
  color: #727171; }
  cite:before {
    content: "— "; }

abbr, abbr[title] {
  border-bottom: 1px dotted #3A3A3A;
  cursor: help;
  text-decoration: none; }

figure {
  margin: 0; }

code {
  padding: 0.125rem 0.3125rem 0.0625rem;
  border: 1px solid #cacaca;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #3A3A3A; }

kbd {
  margin: 0;
  padding: 0.125rem 0.25rem 0;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #3A3A3A; }

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.4;
  color: #727171; }

.lead {
  font-size: 125%;
  line-height: 5.3125rem; }

.stat {
  font-size: 2.5rem;
  line-height: 1; }
  p + .stat {
    margin-top: -1rem; }

ul.no-bullet, ol.no-bullet {
  margin-left: 0;
  list-style: none; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

@media print, screen and (min-width: 40em) {
  .medium-text-left {
    text-align: left; }
  .medium-text-right {
    text-align: right; }
  .medium-text-center {
    text-align: center; }
  .medium-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 64em) {
  .large-text-left {
    text-align: left; }
  .large-text-right {
    text-align: right; }
  .large-text-center {
    text-align: center; }
  .large-text-justify {
    text-align: justify; } }

.show-for-print {
  display: none !important; }

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important; }
  .show-for-print {
    display: block !important; }
  .hide-for-print {
    display: none !important; }
  table.show-for-print {
    display: table !important; }
  thead.show-for-print {
    display: table-header-group !important; }
  tbody.show-for-print {
    display: table-row-group !important; }
  tr.show-for-print {
    display: table-row !important; }
  td.show-for-print {
    display: table-cell !important; }
  th.show-for-print {
    display: table-cell !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: ''; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  pre,
  blockquote {
    border: 1px solid #727171;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .print-break-inside {
    page-break-inside: auto; } }

.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  font-family: inherit;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #824199;
  color: #FFFFFF; }
  [data-whatinput='mouse'] .button {
    outline: 0; }
  .button:hover, .button:focus {
    background-color: #6f3782;
    color: #FFFFFF; }
  .button.tiny {
    font-size: 0.6rem; }
  .button.small {
    font-size: 0.75rem; }
  .button.large {
    font-size: 1.25rem; }
  .button.expanded {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-left: 0; }
  .button.primary {
    background-color: #824199;
    color: #FFFFFF; }
    .button.primary:hover, .button.primary:focus {
      background-color: #68347a;
      color: #FFFFFF; }
  .button.secondary {
    background-color: #767676;
    color: #FFFFFF; }
    .button.secondary:hover, .button.secondary:focus {
      background-color: #5e5e5e;
      color: #FFFFFF; }
  .button.success {
    background-color: #3adb76;
    color: #3A3A3A; }
    .button.success:hover, .button.success:focus {
      background-color: #22bb5b;
      color: #3A3A3A; }
  .button.warning {
    background-color: #ffae00;
    color: #3A3A3A; }
    .button.warning:hover, .button.warning:focus {
      background-color: #cc8b00;
      color: #3A3A3A; }
  .button.alert {
    background-color: #cc4b37;
    color: #FFFFFF; }
    .button.alert:hover, .button.alert:focus {
      background-color: #a53b2a;
      color: #FFFFFF; }
  .button.disabled, .button[disabled] {
    opacity: 0.25;
    cursor: not-allowed; }
    .button.disabled, .button.disabled:hover, .button.disabled:focus, .button[disabled], .button[disabled]:hover, .button[disabled]:focus {
      background-color: #824199;
      color: #FFFFFF; }
    .button.disabled.primary, .button[disabled].primary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.primary, .button.disabled.primary:hover, .button.disabled.primary:focus, .button[disabled].primary, .button[disabled].primary:hover, .button[disabled].primary:focus {
        background-color: #824199;
        color: #FFFFFF; }
    .button.disabled.secondary, .button[disabled].secondary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.secondary, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
        background-color: #767676;
        color: #FFFFFF; }
    .button.disabled.success, .button[disabled].success {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.success, .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success, .button[disabled].success:hover, .button[disabled].success:focus {
        background-color: #3adb76;
        color: #3A3A3A; }
    .button.disabled.warning, .button[disabled].warning {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.warning, .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning, .button[disabled].warning:hover, .button[disabled].warning:focus {
        background-color: #ffae00;
        color: #3A3A3A; }
    .button.disabled.alert, .button[disabled].alert {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.alert, .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert, .button[disabled].alert:hover, .button[disabled].alert:focus {
        background-color: #cc4b37;
        color: #FFFFFF; }
  .button.hollow {
    border: 1px solid #824199;
    color: #824199; }
    .button.hollow, .button.hollow:hover, .button.hollow:focus {
      background-color: transparent; }
    .button.hollow.disabled, .button.hollow.disabled:hover, .button.hollow.disabled:focus, .button.hollow[disabled], .button.hollow[disabled]:hover, .button.hollow[disabled]:focus {
      background-color: transparent; }
    .button.hollow:hover, .button.hollow:focus {
      border-color: #41214d;
      color: #41214d; }
      .button.hollow:hover.disabled, .button.hollow:hover[disabled], .button.hollow:focus.disabled, .button.hollow:focus[disabled] {
        border: 1px solid #824199;
        color: #824199; }
    .button.hollow.primary {
      border: 1px solid #824199;
      color: #824199; }
      .button.hollow.primary:hover, .button.hollow.primary:focus {
        border-color: #41214d;
        color: #41214d; }
        .button.hollow.primary:hover.disabled, .button.hollow.primary:hover[disabled], .button.hollow.primary:focus.disabled, .button.hollow.primary:focus[disabled] {
          border: 1px solid #824199;
          color: #824199; }
    .button.hollow.secondary {
      border: 1px solid #767676;
      color: #767676; }
      .button.hollow.secondary:hover, .button.hollow.secondary:focus {
        border-color: #3b3b3b;
        color: #3b3b3b; }
        .button.hollow.secondary:hover.disabled, .button.hollow.secondary:hover[disabled], .button.hollow.secondary:focus.disabled, .button.hollow.secondary:focus[disabled] {
          border: 1px solid #767676;
          color: #767676; }
    .button.hollow.success {
      border: 1px solid #3adb76;
      color: #3adb76; }
      .button.hollow.success:hover, .button.hollow.success:focus {
        border-color: #157539;
        color: #157539; }
        .button.hollow.success:hover.disabled, .button.hollow.success:hover[disabled], .button.hollow.success:focus.disabled, .button.hollow.success:focus[disabled] {
          border: 1px solid #3adb76;
          color: #3adb76; }
    .button.hollow.warning {
      border: 1px solid #ffae00;
      color: #ffae00; }
      .button.hollow.warning:hover, .button.hollow.warning:focus {
        border-color: #805700;
        color: #805700; }
        .button.hollow.warning:hover.disabled, .button.hollow.warning:hover[disabled], .button.hollow.warning:focus.disabled, .button.hollow.warning:focus[disabled] {
          border: 1px solid #ffae00;
          color: #ffae00; }
    .button.hollow.alert {
      border: 1px solid #cc4b37;
      color: #cc4b37; }
      .button.hollow.alert:hover, .button.hollow.alert:focus {
        border-color: #67251a;
        color: #67251a; }
        .button.hollow.alert:hover.disabled, .button.hollow.alert:hover[disabled], .button.hollow.alert:focus.disabled, .button.hollow.alert:focus[disabled] {
          border: 1px solid #cc4b37;
          color: #cc4b37; }
  .button.clear {
    border: 1px solid #824199;
    color: #824199; }
    .button.clear, .button.clear:hover, .button.clear:focus {
      background-color: transparent; }
    .button.clear.disabled, .button.clear.disabled:hover, .button.clear.disabled:focus, .button.clear[disabled], .button.clear[disabled]:hover, .button.clear[disabled]:focus {
      background-color: transparent; }
    .button.clear:hover, .button.clear:focus {
      border-color: #41214d;
      color: #41214d; }
      .button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus.disabled, .button.clear:focus[disabled] {
        border: 1px solid #824199;
        color: #824199; }
    .button.clear, .button.clear.disabled, .button.clear[disabled], .button.clear:hover, .button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus, .button.clear:focus.disabled, .button.clear:focus[disabled] {
      border-color: transparent; }
    .button.clear.primary {
      border: 1px solid #824199;
      color: #824199; }
      .button.clear.primary:hover, .button.clear.primary:focus {
        border-color: #41214d;
        color: #41214d; }
        .button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
          border: 1px solid #824199;
          color: #824199; }
      .button.clear.primary, .button.clear.primary.disabled, .button.clear.primary[disabled], .button.clear.primary:hover, .button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus, .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
        border-color: transparent; }
    .button.clear.secondary {
      border: 1px solid #767676;
      color: #767676; }
      .button.clear.secondary:hover, .button.clear.secondary:focus {
        border-color: #3b3b3b;
        color: #3b3b3b; }
        .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
          border: 1px solid #767676;
          color: #767676; }
      .button.clear.secondary, .button.clear.secondary.disabled, .button.clear.secondary[disabled], .button.clear.secondary:hover, .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus, .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
        border-color: transparent; }
    .button.clear.success {
      border: 1px solid #3adb76;
      color: #3adb76; }
      .button.clear.success:hover, .button.clear.success:focus {
        border-color: #157539;
        color: #157539; }
        .button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
          border: 1px solid #3adb76;
          color: #3adb76; }
      .button.clear.success, .button.clear.success.disabled, .button.clear.success[disabled], .button.clear.success:hover, .button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus, .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
        border-color: transparent; }
    .button.clear.warning {
      border: 1px solid #ffae00;
      color: #ffae00; }
      .button.clear.warning:hover, .button.clear.warning:focus {
        border-color: #805700;
        color: #805700; }
        .button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
          border: 1px solid #ffae00;
          color: #ffae00; }
      .button.clear.warning, .button.clear.warning.disabled, .button.clear.warning[disabled], .button.clear.warning:hover, .button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus, .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
        border-color: transparent; }
    .button.clear.alert {
      border: 1px solid #cc4b37;
      color: #cc4b37; }
      .button.clear.alert:hover, .button.clear.alert:focus {
        border-color: #67251a;
        color: #67251a; }
        .button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
          border: 1px solid #cc4b37;
          color: #cc4b37; }
      .button.clear.alert, .button.clear.alert.disabled, .button.clear.alert[disabled], .button.clear.alert:hover, .button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus, .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
        border-color: transparent; }
  .button.dropdown::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.4em;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #FFFFFF transparent transparent;
    position: relative;
    top: 0.4em;
    display: inline-block;
    float: right;
    margin-left: 1em; }
  .button.dropdown.hollow::after {
    border-top-color: #824199; }
  .button.dropdown.hollow.primary::after {
    border-top-color: #824199; }
  .button.dropdown.hollow.secondary::after {
    border-top-color: #767676; }
  .button.dropdown.hollow.success::after {
    border-top-color: #3adb76; }
  .button.dropdown.hollow.warning::after {
    border-top-color: #ffae00; }
  .button.dropdown.hollow.alert::after {
    border-top-color: #cc4b37; }
  .button.arrow-only::after {
    top: -0.1em;
    float: none;
    margin-left: 0; }

a.button:hover, a.button:focus {
  text-decoration: none; }

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #FFFFFF;
  box-shadow: inset 0 1px 2px rgba(58, 58, 58, 0.1);
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #3A3A3A;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  appearance: none; }
  [type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus,
  textarea:focus {
    outline: none;
    border: 1px solid #727171;
    background-color: #FFFFFF;
    box-shadow: 0 0 5px #cacaca;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }

textarea {
  max-width: 100%; }
  textarea[rows] {
    height: auto; }

input::placeholder,
textarea::placeholder {
  color: #cacaca; }

input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #e6e6e6;
  cursor: not-allowed; }

[type='submit'],
[type='button'] {
  appearance: none;
  border-radius: 0; }

input[type='search'] {
  box-sizing: border-box; }

[type='file'],
[type='checkbox'],
[type='radio'] {
  margin: 0 0 1rem; }

[type='checkbox'] + label,
[type='radio'] + label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0; }
  [type='checkbox'] + label[for],
  [type='radio'] + label[for] {
    cursor: pointer; }

label > [type='checkbox'],
label > [type='radio'] {
  margin-right: 0.5rem; }

[type='file'] {
  width: 100%; }

label {
  display: block;
  margin: 0;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.8;
  color: #3A3A3A; }
  label.middle {
    margin: 0 0 1rem;
    padding: 0.5625rem 0; }

.help-text {
  margin-top: -0.5rem;
  font-size: 0.8125rem;
  font-style: italic;
  color: #3A3A3A; }

.input-group {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  align-items: stretch; }
  .input-group > :first-child {
    border-radius: 0 0 0 0; }
  .input-group > :last-child > * {
    border-radius: 0 0 0 0; }

.input-group-label, .input-group-field, .input-group-button, .input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  margin: 0;
  white-space: nowrap; }

.input-group-label {
  padding: 0 1rem;
  border: 1px solid #cacaca;
  background: #e6e6e6;
  color: #3A3A3A;
  text-align: center;
  white-space: nowrap;
  display: flex;
  flex: 0 0 auto;
  align-items: center; }
  .input-group-label:first-child {
    border-right: 0; }
  .input-group-label:last-child {
    border-left: 0; }

.input-group-field {
  border-radius: 0;
  flex: 1 1 0px;
  height: auto;
  min-width: 0; }

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  display: flex;
  flex: 0 0 auto; }
  .input-group-button a,
  .input-group-button input,
  .input-group-button button,
  .input-group-button label {
    height: auto;
    align-self: stretch;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1rem; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0; }

legend {
  max-width: 100%;
  margin-bottom: 0.5rem; }

.fieldset {
  margin: 1.125rem 0;
  padding: 1.25rem;
  border: 1px solid #cacaca; }
  .fieldset legend {
    margin: 0;
    margin-left: -0.1875rem;
    padding: 0 0.1875rem; }

select {
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  appearance: none;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #FFFFFF;
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #3A3A3A;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28114, 113, 113%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 1.5rem;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  @media screen and (min-width: 0\0) {
    select {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg=="); } }
  select:focus {
    outline: none;
    border: 1px solid #727171;
    background-color: #FFFFFF;
    box-shadow: 0 0 5px #cacaca;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  select:disabled {
    background-color: #e6e6e6;
    cursor: not-allowed; }
  select::-ms-expand {
    display: none; }
  select[multiple] {
    height: auto;
    background-image: none; }

.is-invalid-input:not(:focus) {
  border-color: #cc4b37;
  background-color: #faedeb; }
  .is-invalid-input:not(:focus)::placeholder {
    color: #cc4b37; }

.is-invalid-label {
  color: #cc4b37; }

.form-error {
  display: none;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: bold;
  color: #cc4b37; }
  .form-error.is-visible {
    display: block; }

.accordion {
  margin-left: 0;
  background: #FFFFFF;
  list-style-type: none; }
  .accordion[disabled] .accordion-title {
    cursor: not-allowed; }

.accordion-item:first-child > :first-child {
  border-radius: 0 0 0 0; }

.accordion-item:last-child > :last-child {
  border-radius: 0 0 0 0; }

.accordion-title {
  position: relative;
  display: block;
  padding: 1.25rem 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  font-size: 0.75rem;
  line-height: 1;
  color: #824199; }
  :last-child:not(.is-active) > .accordion-title {
    border-bottom: 1px solid #e6e6e6;
    border-radius: 0 0 0 0; }
  .accordion-title:hover, .accordion-title:focus {
    background-color: #e6e6e6; }
  .accordion-title::before {
    position: absolute;
    top: 50%;
    right: 1rem;
    margin-top: -0.5rem;
    content: '+'; }
  .is-active > .accordion-title::before {
    content: '\2013'; }

.accordion-content {
  display: none;
  padding: 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  background-color: #FFFFFF;
  color: #3A3A3A; }
  :last-child > .accordion-content:last-child {
    border-bottom: 1px solid #e6e6e6; }

.accordion-menu li {
  width: 100%; }

.accordion-menu a {
  padding: 0.7rem 1rem; }

.accordion-menu .is-accordion-submenu a {
  padding: 0.7rem 1rem; }

.accordion-menu .nested.is-accordion-submenu {
  margin-right: 0;
  margin-left: 1rem; }

.accordion-menu.align-right .nested.is-accordion-submenu {
  margin-right: 1rem;
  margin-left: 0; }

.accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a {
  position: relative; }
  .accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #824199 transparent transparent;
    position: absolute;
    top: 50%;
    margin-top: -3px;
    right: 1rem; }

.accordion-menu.align-left .is-accordion-submenu-parent > a::after {
  left: auto;
  right: 1rem; }

.accordion-menu.align-right .is-accordion-submenu-parent > a::after {
  right: auto;
  left: 1rem; }

.accordion-menu .is-accordion-submenu-parent[aria-expanded='true'] > a::after {
  transform: rotate(180deg);
  transform-origin: 50% 50%; }

.is-accordion-submenu-parent {
  position: relative; }

.has-submenu-toggle > a {
  margin-right: 40px; }

.submenu-toggle {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  width: 40px;
  height: 40px; }
  .submenu-toggle::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #824199 transparent transparent;
    top: 0;
    bottom: 0;
    margin: auto; }

.submenu-toggle[aria-expanded='true']::after {
  transform: scaleY(-1);
  transform-origin: 50% 50%; }

.submenu-toggle-text {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.badge {
  display: inline-block;
  min-width: 2.1em;
  padding: 0.3em;
  border-radius: 50%;
  font-size: 0.6rem;
  text-align: center;
  background: #824199;
  color: #FFFFFF; }
  .badge.primary {
    background: #824199;
    color: #FFFFFF; }
  .badge.secondary {
    background: #767676;
    color: #FFFFFF; }
  .badge.success {
    background: #3adb76;
    color: #3A3A3A; }
  .badge.warning {
    background: #ffae00;
    color: #3A3A3A; }
  .badge.alert {
    background: #cc4b37;
    color: #FFFFFF; }

.breadcrumbs {
  margin: 0 0 1rem 0;
  list-style: none; }
  .breadcrumbs::before, .breadcrumbs::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .breadcrumbs::after {
    clear: both; }
  .breadcrumbs li {
    float: left;
    font-size: 0.6875rem;
    color: #3A3A3A;
    cursor: default;
    text-transform: uppercase; }
    .breadcrumbs li:not(:last-child)::after {
      position: relative;
      margin: 0 0.75rem;
      opacity: 1;
      content: "/";
      color: #cacaca; }
  .breadcrumbs a {
    color: #824199; }
    .breadcrumbs a:hover {
      text-decoration: underline; }
  .breadcrumbs .disabled {
    color: #cacaca;
    cursor: not-allowed; }

.button-group {
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch; }
  .button-group::before, .button-group::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .button-group::after {
    clear: both; }
  .button-group .button {
    margin: 0;
    margin-right: 1px;
    margin-bottom: 1px;
    font-size: 0.9rem;
    flex: 0 0 auto; }
    .button-group .button:last-child {
      margin-right: 0; }
  .button-group.tiny .button {
    font-size: 0.6rem; }
  .button-group.small .button {
    font-size: 0.75rem; }
  .button-group.large .button {
    font-size: 1.25rem; }
  .button-group.expanded .button {
    flex: 1 1 0px; }
  .button-group.primary .button {
    background-color: #824199;
    color: #FFFFFF; }
    .button-group.primary .button:hover, .button-group.primary .button:focus {
      background-color: #68347a;
      color: #FFFFFF; }
  .button-group.secondary .button {
    background-color: #767676;
    color: #FFFFFF; }
    .button-group.secondary .button:hover, .button-group.secondary .button:focus {
      background-color: #5e5e5e;
      color: #FFFFFF; }
  .button-group.success .button {
    background-color: #3adb76;
    color: #3A3A3A; }
    .button-group.success .button:hover, .button-group.success .button:focus {
      background-color: #22bb5b;
      color: #3A3A3A; }
  .button-group.warning .button {
    background-color: #ffae00;
    color: #3A3A3A; }
    .button-group.warning .button:hover, .button-group.warning .button:focus {
      background-color: #cc8b00;
      color: #3A3A3A; }
  .button-group.alert .button {
    background-color: #cc4b37;
    color: #FFFFFF; }
    .button-group.alert .button:hover, .button-group.alert .button:focus {
      background-color: #a53b2a;
      color: #FFFFFF; }
  .button-group.stacked, .button-group.stacked-for-small, .button-group.stacked-for-medium {
    flex-wrap: wrap; }
    .button-group.stacked .button, .button-group.stacked-for-small .button, .button-group.stacked-for-medium .button {
      flex: 0 0 100%; }
      .button-group.stacked .button:last-child, .button-group.stacked-for-small .button:last-child, .button-group.stacked-for-medium .button:last-child {
        margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .button-group.stacked-for-small .button {
      flex: 1 1 0px;
      margin-bottom: 0; } }
  @media print, screen and (min-width: 64em) {
    .button-group.stacked-for-medium .button {
      flex: 1 1 0px;
      margin-bottom: 0; } }
  @media screen and (max-width: 39.9375em) {
    .button-group.stacked-for-small.expanded {
      display: block; }
      .button-group.stacked-for-small.expanded .button {
        display: block;
        margin-right: 0; } }

.callout {
  position: relative;
  margin: 0 0 1rem 0;
  padding: 1rem;
  border: 1px solid rgba(58, 58, 58, 0.25);
  border-radius: 0;
  background-color: white;
  color: #3A3A3A; }
  .callout > :first-child {
    margin-top: 0; }
  .callout > :last-child {
    margin-bottom: 0; }
  .callout.primary {
    background-color: #ede0f2;
    color: #3A3A3A; }
  .callout.secondary {
    background-color: #eaeaea;
    color: #3A3A3A; }
  .callout.success {
    background-color: #e1faea;
    color: #3A3A3A; }
  .callout.warning {
    background-color: #fff3d9;
    color: #3A3A3A; }
  .callout.alert {
    background-color: #f7e4e1;
    color: #3A3A3A; }
  .callout.small {
    padding-top: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem; }
  .callout.large {
    padding-top: 3rem;
    padding-right: 3rem;
    padding-bottom: 3rem;
    padding-left: 3rem; }

.card {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 1rem;
  border: 1px solid #e6e6e6;
  border-radius: 0;
  background: #FFFFFF;
  box-shadow: none;
  overflow: hidden;
  color: #3A3A3A; }
  .card > :last-child {
    margin-bottom: 0; }

.card-divider {
  flex: 0 1 auto;
  display: flex;
  padding: 1rem;
  background: #e6e6e6; }
  .card-divider > :last-child {
    margin-bottom: 0; }

.card-section {
  flex: 1 0 auto;
  padding: 1rem; }
  .card-section > :last-child {
    margin-bottom: 0; }

.card-image {
  min-height: 1px; }

.close-button {
  position: absolute;
  color: #727171;
  cursor: pointer; }
  [data-whatinput='mouse'] .close-button {
    outline: 0; }
  .close-button:hover, .close-button:focus {
    color: #3A3A3A; }
  .close-button.small {
    right: 0.66rem;
    top: 0.33em;
    font-size: 1.5em;
    line-height: 1; }
  .close-button, .close-button.medium {
    right: 1rem;
    top: 0.5rem;
    font-size: 2em;
    line-height: 1; }

.menu {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  display: flex;
  flex-wrap: wrap; }
  [data-whatinput='mouse'] .menu li {
    outline: 0; }
  .menu a,
  .menu .button {
    line-height: 1;
    text-decoration: none;
    display: block;
    padding: 0.7rem 1rem; }
  .menu input,
  .menu select,
  .menu a,
  .menu button {
    margin-bottom: 0; }
  .menu input {
    display: inline-block; }
  .menu, .menu.horizontal {
    flex-wrap: wrap;
    flex-direction: row; }
  .menu.vertical {
    flex-wrap: nowrap;
    flex-direction: column; }
  .menu.expanded li {
    flex: 1 1 0px; }
  .menu.simple {
    align-items: center; }
    .menu.simple li + li {
      margin-left: 1rem; }
    .menu.simple a {
      padding: 0; }
  @media print, screen and (min-width: 40em) {
    .menu.medium-horizontal {
      flex-wrap: wrap;
      flex-direction: row; }
    .menu.medium-vertical {
      flex-wrap: nowrap;
      flex-direction: column; }
    .menu.medium-expanded li {
      flex: 1 1 0px; }
    .menu.medium-simple li {
      flex: 1 1 0px; } }
  @media print, screen and (min-width: 64em) {
    .menu.large-horizontal {
      flex-wrap: wrap;
      flex-direction: row; }
    .menu.large-vertical {
      flex-wrap: nowrap;
      flex-direction: column; }
    .menu.large-expanded li {
      flex: 1 1 0px; }
    .menu.large-simple li {
      flex: 1 1 0px; } }
  .menu.nested {
    margin-right: 0;
    margin-left: 1rem; }
  .menu.icons a {
    display: flex; }
  .menu.icon-top a, .menu.icon-right a, .menu.icon-bottom a, .menu.icon-left a {
    display: flex; }
  .menu.icon-left li a {
    flex-flow: row nowrap; }
    .menu.icon-left li a img,
    .menu.icon-left li a i,
    .menu.icon-left li a svg {
      margin-right: 0.25rem; }
  .menu.icon-right li a {
    flex-flow: row nowrap; }
    .menu.icon-right li a img,
    .menu.icon-right li a i,
    .menu.icon-right li a svg {
      margin-left: 0.25rem; }
  .menu.icon-top li a {
    flex-flow: column nowrap; }
    .menu.icon-top li a img,
    .menu.icon-top li a i,
    .menu.icon-top li a svg {
      align-self: stretch;
      margin-bottom: 0.25rem;
      text-align: center; }
  .menu.icon-bottom li a {
    flex-flow: column nowrap; }
    .menu.icon-bottom li a img,
    .menu.icon-bottom li a i,
    .menu.icon-bottom li a svg {
      align-self: stretch;
      margin-bottom: 0.25rem;
      text-align: center; }
  .menu .is-active > a {
    background: #824199;
    color: #FFFFFF; }
  .menu .active > a {
    background: #824199;
    color: #FFFFFF; }
  .menu.align-left {
    justify-content: flex-start; }
  .menu.align-right li {
    display: flex;
    justify-content: flex-end; }
    .menu.align-right li .submenu li {
      justify-content: flex-start; }
  .menu.align-right.vertical li {
    display: block;
    text-align: right; }
    .menu.align-right.vertical li .submenu li {
      text-align: right; }
  .menu.align-right .nested {
    margin-right: 1rem;
    margin-left: 0; }
  .menu.align-center li {
    display: flex;
    justify-content: center; }
    .menu.align-center li .submenu li {
      justify-content: flex-start; }
  .menu .menu-text {
    padding: 0.7rem 1rem;
    font-weight: bold;
    line-height: 1;
    color: inherit; }

.menu-centered > .menu {
  justify-content: center; }
  .menu-centered > .menu li {
    display: flex;
    justify-content: center; }
    .menu-centered > .menu li .submenu li {
      justify-content: flex-start; }

.no-js [data-responsive-menu] ul {
  display: none; }

.menu-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }
  .menu-icon::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #FFFFFF;
    box-shadow: 0 7px 0 #FFFFFF, 0 14px 0 #FFFFFF;
    content: ''; }
  .menu-icon:hover::after {
    background: #cacaca;
    box-shadow: 0 7px 0 #cacaca, 0 14px 0 #cacaca; }

.menu-icon.dark {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }
  .menu-icon.dark::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #3A3A3A;
    box-shadow: 0 7px 0 #3A3A3A, 0 14px 0 #3A3A3A;
    content: ''; }
  .menu-icon.dark:hover::after {
    background: #727171;
    box-shadow: 0 7px 0 #727171, 0 14px 0 #727171; }

.is-drilldown {
  position: relative;
  overflow: hidden; }
  .is-drilldown li {
    display: block; }
  .is-drilldown.animate-height {
    transition: height 0.5s; }

.drilldown a {
  padding: 0.7rem 1rem;
  background: #FFFFFF; }

.drilldown .is-drilldown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: -1;
  width: 100%;
  background: #FFFFFF;
  transition: transform 0.15s linear; }
  .drilldown .is-drilldown-submenu.is-active {
    z-index: 1;
    display: block;
    transform: translateX(-100%); }
  .drilldown .is-drilldown-submenu.is-closing {
    transform: translateX(100%); }
  .drilldown .is-drilldown-submenu a {
    padding: 0.7rem 1rem; }

.drilldown .nested.is-drilldown-submenu {
  margin-right: 0;
  margin-left: 0; }

.drilldown .drilldown-submenu-cover-previous {
  min-height: 100%; }

.drilldown .is-drilldown-submenu-parent > a {
  position: relative; }
  .drilldown .is-drilldown-submenu-parent > a::after {
    position: absolute;
    top: 50%;
    margin-top: -6px;
    right: 1rem;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #824199; }

.drilldown.align-left .is-drilldown-submenu-parent > a::after {
  left: auto;
  right: 1rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #824199; }

.drilldown.align-right .is-drilldown-submenu-parent > a::after {
  right: auto;
  left: 1rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #824199 transparent transparent; }

.drilldown .js-drilldown-back > a::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #824199 transparent transparent;
  border-left-width: 0;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.75rem;
  border-left-width: 0; }

.dropdown-pane {
  position: absolute;
  z-index: 10;
  width: 300px;
  padding: 1rem;
  visibility: hidden;
  display: none;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #FFFFFF;
  font-size: 1rem; }
  .dropdown-pane.is-opening {
    display: block; }
  .dropdown-pane.is-open {
    visibility: visible;
    display: block; }

.dropdown-pane.tiny {
  width: 100px; }

.dropdown-pane.small {
  width: 200px; }

.dropdown-pane.large {
  width: 400px; }

.dropdown.menu > li.opens-left > .is-dropdown-submenu {
  top: 100%;
  right: 0;
  left: auto; }

.dropdown.menu > li.opens-right > .is-dropdown-submenu {
  top: 100%;
  right: auto;
  left: 0; }

.dropdown.menu > li.is-dropdown-submenu-parent > a {
  position: relative;
  padding-right: 1.5rem; }

.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #824199 transparent transparent;
  right: 5px;
  left: auto;
  margin-top: -3px; }

.dropdown.menu a {
  padding: 0.7rem 1rem; }
  [data-whatinput='mouse'] .dropdown.menu a {
    outline: 0; }

.dropdown.menu .is-active > a {
  background: transparent;
  color: #824199; }

.no-js .dropdown.menu ul {
  display: none; }

.dropdown.menu .nested.is-dropdown-submenu {
  margin-right: 0;
  margin-left: 0; }

.dropdown.menu.vertical > li .is-dropdown-submenu {
  top: 0; }

.dropdown.menu.vertical > li.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto;
  top: 0; }

.dropdown.menu.vertical > li.opens-right > .is-dropdown-submenu {
  right: auto;
  left: 100%; }

.dropdown.menu.vertical > li > a::after {
  right: 14px; }

.dropdown.menu.vertical > li.opens-left > a::after {
  right: auto;
  left: 5px;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #824199 transparent transparent; }

.dropdown.menu.vertical > li.opens-right > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #824199; }

@media print, screen and (min-width: 40em) {
  .dropdown.menu.medium-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.medium-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #824199 transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px; }
  .dropdown.menu.medium-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.medium-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.medium-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #824199 transparent transparent; }
  .dropdown.menu.medium-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #824199; } }

@media print, screen and (min-width: 64em) {
  .dropdown.menu.large-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.large-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #824199 transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px; }
  .dropdown.menu.large-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.large-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.large-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #824199 transparent transparent; }
  .dropdown.menu.large-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #824199; } }

.dropdown.menu.align-right .is-dropdown-submenu.first-sub {
  top: 100%;
  right: 0;
  left: auto; }

.is-dropdown-menu.vertical {
  width: 100px; }
  .is-dropdown-menu.vertical.align-right {
    float: right; }

.is-dropdown-submenu-parent {
  position: relative; }
  .is-dropdown-submenu-parent a::after {
    position: absolute;
    top: 50%;
    right: 5px;
    left: auto;
    margin-top: -6px; }
  .is-dropdown-submenu-parent.opens-inner > .is-dropdown-submenu {
    top: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }

.is-dropdown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
  display: none;
  min-width: 200px;
  border: 1px solid #cacaca;
  background: #FFFFFF; }
  .dropdown .is-dropdown-submenu a {
    padding: 0.7rem 1rem; }
  .is-dropdown-submenu .is-dropdown-submenu-parent > a::after {
    right: 14px; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #824199 transparent transparent; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #824199; }
  .is-dropdown-submenu .is-dropdown-submenu {
    margin-top: -1px; }
  .is-dropdown-submenu > li {
    width: 100%; }
  .is-dropdown-submenu.js-dropdown-active {
    display: block; }

.responsive-embed,
.flex-video {
  position: relative;
  height: 0;
  margin-bottom: 1rem;
  padding-bottom: 75%;
  overflow: hidden; }
  .responsive-embed iframe,
  .responsive-embed object,
  .responsive-embed embed,
  .responsive-embed video,
  .flex-video iframe,
  .flex-video object,
  .flex-video embed,
  .flex-video video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .responsive-embed.widescreen,
  .flex-video.widescreen {
    padding-bottom: 56.25%; }

.label {
  display: inline-block;
  padding: 0.33333rem 0.5rem;
  border-radius: 0;
  font-size: 0.8rem;
  line-height: 1;
  white-space: nowrap;
  cursor: default;
  background: #824199;
  color: #FFFFFF; }
  .label.primary {
    background: #824199;
    color: #FFFFFF; }
  .label.secondary {
    background: #767676;
    color: #FFFFFF; }
  .label.success {
    background: #3adb76;
    color: #3A3A3A; }
  .label.warning {
    background: #ffae00;
    color: #3A3A3A; }
  .label.alert {
    background: #cc4b37;
    color: #FFFFFF; }

.media-object {
  display: flex;
  margin-bottom: 1rem;
  flex-wrap: nowrap; }
  .media-object img {
    max-width: none; }
  @media screen and (max-width: 39.9375em) {
    .media-object.stack-for-small {
      flex-wrap: wrap; } }
  @media screen and (max-width: 39.9375em) {
    .media-object.stack-for-small .media-object-section {
      padding: 0;
      padding-bottom: 1rem;
      flex-basis: 100%;
      max-width: 100%; }
      .media-object.stack-for-small .media-object-section img {
        width: 100%; } }

.media-object-section {
  flex: 0 1 auto; }
  .media-object-section:first-child {
    padding-right: 1rem; }
  .media-object-section:last-child:not(:nth-child(2)) {
    padding-left: 1rem; }
  .media-object-section > :last-child {
    margin-bottom: 0; }
  .media-object-section.main-section {
    flex: 1 1 0px; }

.is-off-canvas-open {
  overflow: hidden; }

.js-off-canvas-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  background: rgba(255, 255, 255, 0.25);
  opacity: 0;
  visibility: hidden;
  overflow: hidden; }
  .js-off-canvas-overlay.is-visible {
    opacity: 1;
    visibility: visible; }
  .js-off-canvas-overlay.is-closable {
    cursor: pointer; }
  .js-off-canvas-overlay.is-overlay-absolute {
    position: absolute; }
  .js-off-canvas-overlay.is-overlay-fixed {
    position: fixed; }

.off-canvas-wrapper {
  position: relative;
  overflow: hidden; }

.off-canvas {
  position: fixed;
  z-index: 12;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #e6e6e6; }
  [data-whatinput='mouse'] .off-canvas {
    outline: 0; }
  .off-canvas.is-transition-push {
    z-index: 12; }
  .off-canvas.is-closed {
    visibility: hidden; }
  .off-canvas.is-transition-overlap {
    z-index: 13; }
    .off-canvas.is-transition-overlap.is-open {
      box-shadow: 0 0 10px rgba(58, 58, 58, 0.7); }
  .off-canvas.is-open {
    transform: translate(0, 0); }

.off-canvas-absolute {
  position: absolute;
  z-index: 12;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #e6e6e6; }
  [data-whatinput='mouse'] .off-canvas-absolute {
    outline: 0; }
  .off-canvas-absolute.is-transition-push {
    z-index: 12; }
  .off-canvas-absolute.is-closed {
    visibility: hidden; }
  .off-canvas-absolute.is-transition-overlap {
    z-index: 13; }
    .off-canvas-absolute.is-transition-overlap.is-open {
      box-shadow: 0 0 10px rgba(58, 58, 58, 0.7); }
  .off-canvas-absolute.is-open {
    transform: translate(0, 0); }

.position-left {
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
  width: 250px;
  transform: translateX(-250px); }
  @media print, screen and (min-width: 40em) {
    .position-left {
      width: 250px;
      transform: translateX(-250px); } }
  .off-canvas-content .off-canvas.position-left {
    transform: translateX(-250px); }
    @media print, screen and (min-width: 40em) {
      .off-canvas-content .off-canvas.position-left {
        transform: translateX(-250px); } }
    .off-canvas-content .off-canvas.position-left.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-left.has-transition-push {
    transform: translateX(250px); }
    @media print, screen and (min-width: 40em) {
      .off-canvas-content.is-open-left.has-transition-push {
        transform: translateX(250px); } }
  .position-left.is-transition-push {
    box-shadow: inset -13px 0 20px -13px rgba(58, 58, 58, 0.25); }

.position-right {
  top: 0;
  right: 0;
  height: 100%;
  overflow-y: auto;
  width: 250px;
  transform: translateX(250px); }
  @media print, screen and (min-width: 40em) {
    .position-right {
      width: 250px;
      transform: translateX(250px); } }
  .off-canvas-content .off-canvas.position-right {
    transform: translateX(250px); }
    @media print, screen and (min-width: 40em) {
      .off-canvas-content .off-canvas.position-right {
        transform: translateX(250px); } }
    .off-canvas-content .off-canvas.position-right.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-right.has-transition-push {
    transform: translateX(-250px); }
    @media print, screen and (min-width: 40em) {
      .off-canvas-content.is-open-right.has-transition-push {
        transform: translateX(-250px); } }
  .position-right.is-transition-push {
    box-shadow: inset 13px 0 20px -13px rgba(58, 58, 58, 0.25); }

.position-top {
  top: 0;
  left: 0;
  width: 100%;
  overflow-x: auto;
  height: 250px;
  transform: translateY(-250px); }
  @media print, screen and (min-width: 40em) {
    .position-top {
      height: 250px;
      transform: translateY(-250px); } }
  .off-canvas-content .off-canvas.position-top {
    transform: translateY(-250px); }
    @media print, screen and (min-width: 40em) {
      .off-canvas-content .off-canvas.position-top {
        transform: translateY(-250px); } }
    .off-canvas-content .off-canvas.position-top.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-top.has-transition-push {
    transform: translateY(250px); }
    @media print, screen and (min-width: 40em) {
      .off-canvas-content.is-open-top.has-transition-push {
        transform: translateY(250px); } }
  .position-top.is-transition-push {
    box-shadow: inset 0 -13px 20px -13px rgba(58, 58, 58, 0.25); }

.position-bottom {
  bottom: 0;
  left: 0;
  width: 100%;
  overflow-x: auto;
  height: 250px;
  transform: translateY(250px); }
  @media print, screen and (min-width: 40em) {
    .position-bottom {
      height: 250px;
      transform: translateY(250px); } }
  .off-canvas-content .off-canvas.position-bottom {
    transform: translateY(250px); }
    @media print, screen and (min-width: 40em) {
      .off-canvas-content .off-canvas.position-bottom {
        transform: translateY(250px); } }
    .off-canvas-content .off-canvas.position-bottom.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-bottom.has-transition-push {
    transform: translateY(-250px); }
    @media print, screen and (min-width: 40em) {
      .off-canvas-content.is-open-bottom.has-transition-push {
        transform: translateY(-250px); } }
  .position-bottom.is-transition-push {
    box-shadow: inset 0 13px 20px -13px rgba(58, 58, 58, 0.25); }

.off-canvas-content {
  transform: none;
  transition: transform 0.5s ease;
  backface-visibility: hidden; }
  .off-canvas-content.has-transition-push {
    transform: translate(0, 0); }
  .off-canvas-content .off-canvas.is-open {
    transform: translate(0, 0); }

@media print, screen and (min-width: 40em) {
  .position-left.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-left.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-left.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-left {
      margin-left: 250px; }
    .position-left.reveal-for-medium ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-right.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-right.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-right {
      margin-right: 250px; }
    .position-right.reveal-for-medium ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-top.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-top.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-top {
      margin-top: 250px; }
    .position-top.reveal-for-medium ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-bottom.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-bottom.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-bottom {
      margin-bottom: 250px; }
    .position-bottom.reveal-for-medium ~ .off-canvas-content {
      margin-bottom: 250px; } }

@media print, screen and (min-width: 64em) {
  .position-left.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-left.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-left.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-left {
      margin-left: 250px; }
    .position-left.reveal-for-large ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-right.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-right.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-right {
      margin-right: 250px; }
    .position-right.reveal-for-large ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-top.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-top.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-top {
      margin-top: 250px; }
    .position-top.reveal-for-large ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-bottom.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-bottom.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-bottom {
      margin-bottom: 250px; }
    .position-bottom.reveal-for-large ~ .off-canvas-content {
      margin-bottom: 250px; } }

@media print, screen and (min-width: 40em) {
  .off-canvas.in-canvas-for-medium {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit; }
    .off-canvas.in-canvas-for-medium.position-left, .off-canvas.in-canvas-for-medium.position-right, .off-canvas.in-canvas-for-medium.position-top, .off-canvas.in-canvas-for-medium.position-bottom {
      box-shadow: none;
      transform: none; }
    .off-canvas.in-canvas-for-medium .close-button {
      display: none; } }

@media print, screen and (min-width: 64em) {
  .off-canvas.in-canvas-for-large {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit; }
    .off-canvas.in-canvas-for-large.position-left, .off-canvas.in-canvas-for-large.position-right, .off-canvas.in-canvas-for-large.position-top, .off-canvas.in-canvas-for-large.position-bottom {
      box-shadow: none;
      transform: none; }
    .off-canvas.in-canvas-for-large .close-button {
      display: none; } }

.orbit {
  position: relative; }

.orbit-container {
  position: relative;
  height: 0;
  margin: 0;
  list-style: none;
  overflow: hidden; }

.orbit-slide {
  width: 100%; }
  .orbit-slide.no-motionui.is-active {
    top: 0;
    left: 0; }

.orbit-figure {
  margin: 0; }

.orbit-image {
  width: 100%;
  max-width: 100%;
  margin: 0; }

.orbit-caption {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 0;
  padding: 1rem;
  background-color: rgba(58, 58, 58, 0.5);
  color: #FFFFFF; }

.orbit-previous, .orbit-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  padding: 1rem;
  color: #FFFFFF; }
  [data-whatinput='mouse'] .orbit-previous, [data-whatinput='mouse'] .orbit-next {
    outline: 0; }
  .orbit-previous:hover, .orbit-next:hover, .orbit-previous:active, .orbit-next:active, .orbit-previous:focus, .orbit-next:focus {
    background-color: rgba(58, 58, 58, 0.5); }

.orbit-previous {
  left: 0; }

.orbit-next {
  left: auto;
  right: 0; }

.orbit-bullets {
  position: relative;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  text-align: center; }
  [data-whatinput='mouse'] .orbit-bullets {
    outline: 0; }
  .orbit-bullets button {
    width: 1.2rem;
    height: 1.2rem;
    margin: 0.1rem;
    border-radius: 50%;
    background-color: #cacaca; }
    .orbit-bullets button:hover {
      background-color: #727171; }
    .orbit-bullets button.is-active {
      background-color: #727171; }

.pagination {
  margin-left: 0;
  margin-bottom: 1rem; }
  .pagination::before, .pagination::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .pagination::after {
    clear: both; }
  .pagination li {
    margin-right: 0.0625rem;
    border-radius: 0;
    font-size: 0.875rem;
    display: none; }
    .pagination li:last-child, .pagination li:first-child {
      display: inline-block; }
    @media print, screen and (min-width: 40em) {
      .pagination li {
        display: inline-block; } }
  .pagination a,
  .pagination button {
    display: block;
    padding: 0.1875rem 0.625rem;
    border-radius: 0;
    color: #3A3A3A; }
    .pagination a:hover,
    .pagination button:hover {
      background: #e6e6e6; }
  .pagination .current {
    padding: 0.1875rem 0.625rem;
    background: #824199;
    color: #FFFFFF;
    cursor: default; }
  .pagination .disabled {
    padding: 0.1875rem 0.625rem;
    color: #cacaca;
    cursor: not-allowed; }
    .pagination .disabled:hover {
      background: transparent; }
  .pagination .ellipsis::after {
    padding: 0.1875rem 0.625rem;
    content: '\2026';
    color: #3A3A3A; }

.pagination-previous a::before,
.pagination-previous.disabled::before {
  display: inline-block;
  margin-right: 0.5rem;
  content: '\00ab'; }

.pagination-next a::after,
.pagination-next.disabled::after {
  display: inline-block;
  margin-left: 0.5rem;
  content: '\00bb'; }

.progress {
  height: 1rem;
  margin-bottom: 1rem;
  border-radius: 0;
  background-color: #cacaca; }
  .progress.primary .progress-meter {
    background-color: #824199; }
  .progress.secondary .progress-meter {
    background-color: #767676; }
  .progress.success .progress-meter {
    background-color: #3adb76; }
  .progress.warning .progress-meter {
    background-color: #ffae00; }
  .progress.alert .progress-meter {
    background-color: #cc4b37; }

.progress-meter {
  position: relative;
  display: block;
  width: 0%;
  height: 100%;
  background-color: #824199; }

.progress-meter-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  margin: 0;
  font-size: 0.75rem;
  font-weight: bold;
  color: #FFFFFF;
  white-space: nowrap; }

.slider {
  position: relative;
  height: 0.5rem;
  margin-top: 1.25rem;
  margin-bottom: 2.25rem;
  background-color: #e6e6e6;
  cursor: pointer;
  user-select: none;
  touch-action: none; }

.slider-fill {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  max-width: 100%;
  height: 0.5rem;
  background-color: #cacaca;
  transition: all 0.2s ease-in-out; }
  .slider-fill.is-dragging {
    transition: all 0s linear; }

.slider-handle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 1;
  display: inline-block;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 0;
  background-color: #824199;
  transition: all 0.2s ease-in-out;
  touch-action: manipulation; }
  [data-whatinput='mouse'] .slider-handle {
    outline: 0; }
  .slider-handle:hover {
    background-color: #6f3782; }
  .slider-handle.is-dragging {
    transition: all 0s linear; }

.slider.disabled,
.slider[disabled] {
  opacity: 0.25;
  cursor: not-allowed; }

.slider.vertical {
  display: inline-block;
  width: 0.5rem;
  height: 12.5rem;
  margin: 0 1.25rem;
  transform: scale(1, -1); }
  .slider.vertical .slider-fill {
    top: 0;
    width: 0.5rem;
    max-height: 100%; }
  .slider.vertical .slider-handle {
    position: absolute;
    top: 0;
    left: 50%;
    width: 1.4rem;
    height: 1.4rem;
    transform: translateX(-50%); }

.sticky-container {
  position: relative; }

.sticky {
  position: relative;
  z-index: 0;
  transform: translate3d(0, 0, 0); }

.sticky.is-stuck {
  position: fixed;
  z-index: 5;
  width: 100%; }
  .sticky.is-stuck.is-at-top {
    top: 0; }
  .sticky.is-stuck.is-at-bottom {
    bottom: 0; }

.sticky.is-anchored {
  position: relative;
  right: auto;
  left: auto; }
  .sticky.is-anchored.is-at-bottom {
    bottom: 0; }

body.is-reveal-open {
  overflow: hidden; }

html.is-reveal-open,
html.is-reveal-open body {
  min-height: 100%;
  overflow: hidden;
  position: fixed;
  user-select: none; }

.reveal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  display: none;
  background-color: rgba(58, 58, 58, 0.45);
  overflow-y: scroll; }

.reveal {
  z-index: 1006;
  backface-visibility: hidden;
  display: none;
  padding: 1rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #FFFFFF;
  position: relative;
  top: 100px;
  margin-right: auto;
  margin-left: auto;
  overflow-y: auto; }
  [data-whatinput='mouse'] .reveal {
    outline: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal {
      min-height: 0; } }
  .reveal .column, .reveal .columns {
    min-width: 0; }
  .reveal > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal {
      width: 600px;
      max-width: 75rem; } }
  .reveal.collapse {
    padding: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal.tiny {
      width: 30%;
      max-width: 75rem; } }
  @media print, screen and (min-width: 40em) {
    .reveal.small {
      width: 50%;
      max-width: 75rem; } }
  @media print, screen and (min-width: 40em) {
    .reveal.large {
      width: 90%;
      max-width: 75rem; } }
  .reveal.full {
    top: 0;
    left: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    margin-left: 0;
    border: 0;
    border-radius: 0; }
  @media screen and (max-width: 39.9375em) {
    .reveal {
      top: 0;
      left: 0;
      width: 100%;
      max-width: none;
      height: 100%;
      height: 100vh;
      min-height: 100vh;
      margin-left: 0;
      border: 0;
      border-radius: 0; } }
  .reveal.without-overlay {
    position: fixed; }

.switch {
  height: 2rem;
  position: relative;
  margin-bottom: 1rem;
  outline: 0;
  font-size: 0.875rem;
  font-weight: bold;
  color: #FFFFFF;
  user-select: none; }

.switch-input {
  position: absolute;
  margin-bottom: 0;
  opacity: 0; }

.switch-paddle {
  position: relative;
  display: block;
  width: 4rem;
  height: 2rem;
  border-radius: 0;
  background: #cacaca;
  transition: all 0.25s ease-out;
  font-weight: inherit;
  color: inherit;
  cursor: pointer; }
  input + .switch-paddle {
    margin: 0; }
  .switch-paddle::after {
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    transform: translate3d(0, 0, 0);
    border-radius: 0;
    background: #FFFFFF;
    transition: all 0.25s ease-out;
    content: ''; }
  input:checked ~ .switch-paddle {
    background: #824199; }
    input:checked ~ .switch-paddle::after {
      left: 2.25rem; }
  [data-whatinput='mouse'] input:focus ~ .switch-paddle {
    outline: 0; }

.switch-active, .switch-inactive {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.switch-active {
  left: 8%;
  display: none; }
  input:checked + label > .switch-active {
    display: block; }

.switch-inactive {
  right: 15%; }
  input:checked + label > .switch-inactive {
    display: none; }

.switch.tiny {
  height: 1.5rem; }
  .switch.tiny .switch-paddle {
    width: 3rem;
    height: 1.5rem;
    font-size: 0.625rem; }
  .switch.tiny .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 1rem;
    height: 1rem; }
  .switch.tiny input:checked ~ .switch-paddle::after {
    left: 1.75rem; }

.switch.small {
  height: 1.75rem; }
  .switch.small .switch-paddle {
    width: 3.5rem;
    height: 1.75rem;
    font-size: 0.75rem; }
  .switch.small .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 1.25rem;
    height: 1.25rem; }
  .switch.small input:checked ~ .switch-paddle::after {
    left: 2rem; }

.switch.large {
  height: 2.5rem; }
  .switch.large .switch-paddle {
    width: 5rem;
    height: 2.5rem;
    font-size: 1rem; }
  .switch.large .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 2rem;
    height: 2rem; }
  .switch.large input:checked ~ .switch-paddle::after {
    left: 2.75rem; }

table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 0; }
  table thead,
  table tbody,
  table tfoot {
    border: 1px solid #f2f2f2;
    background-color: #FFFFFF; }
  table caption {
    padding: 0.5rem 0.625rem 0.625rem;
    font-weight: bold; }
  table thead {
    background: #f9f9f9;
    color: #3A3A3A; }
  table tfoot {
    background: #f2f2f2;
    color: #3A3A3A; }
  table thead tr,
  table tfoot tr {
    background: transparent; }
  table thead th,
  table thead td,
  table tfoot th,
  table tfoot td {
    padding: 0.5rem 0.625rem 0.625rem;
    font-weight: bold;
    text-align: left; }
  table tbody th,
  table tbody td {
    padding: 0.5rem 0.625rem 0.625rem; }
  table tbody tr:nth-child(even) {
    border-bottom: 0;
    background-color: #f2f2f2; }
  table.unstriped tbody {
    background-color: #FFFFFF; }
    table.unstriped tbody tr {
      border-bottom: 0;
      border-bottom: 1px solid #f2f2f2;
      background-color: #FFFFFF; }

@media screen and (max-width: 63.9375em) {
  table.stack thead {
    display: none; }
  table.stack tfoot {
    display: none; }
  table.stack tr,
  table.stack th,
  table.stack td {
    display: block; }
  table.stack td {
    border-top: 0; } }

table.scroll {
  display: block;
  width: 100%;
  overflow-x: auto; }

table.hover thead tr:hover {
  background-color: #f4f4f4; }

table.hover tfoot tr:hover {
  background-color: #ededed; }

table.hover tbody tr:hover {
  background-color: #fafafa; }

table.hover:not(.unstriped) tr:nth-of-type(even):hover {
  background-color: #ededed; }

.table-scroll {
  overflow-x: auto; }
  .table-scroll table {
    width: auto; }

.tabs {
  margin: 0;
  border: 1px solid #e6e6e6;
  background: #FFFFFF;
  list-style-type: none; }
  .tabs::before, .tabs::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .tabs::after {
    clear: both; }

.tabs.vertical > li {
  display: block;
  float: none;
  width: auto; }

.tabs.simple > li > a {
  padding: 0; }
  .tabs.simple > li > a:hover {
    background: transparent; }

.tabs.primary {
  background: #824199; }
  .tabs.primary > li > a {
    color: #FFFFFF; }
    .tabs.primary > li > a:hover, .tabs.primary > li > a:focus {
      background: #7c3e91; }

.tabs-title {
  float: left; }
  .tabs-title > a {
    display: block;
    padding: 1.25rem 1.5rem;
    font-size: 0.75rem;
    line-height: 1;
    color: #824199; }
    .tabs-title > a:hover {
      background: #FFFFFF;
      color: #703884; }
    .tabs-title > a:focus, .tabs-title > a[aria-selected='true'] {
      background: #e6e6e6;
      color: #824199; }

.tabs-content {
  border: 1px solid #e6e6e6;
  border-top: 0;
  background: #FFFFFF;
  color: #3A3A3A;
  transition: all 0.5s ease; }

.tabs-content.vertical {
  border: 1px solid #e6e6e6;
  border-left: 0; }

.tabs-panel {
  display: none;
  padding: 1rem; }
  .tabs-panel.is-active {
    display: block; }

.thumbnail {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 1rem;
  border: solid 4px #FFFFFF;
  border-radius: 0;
  box-shadow: 0 0 0 1px rgba(58, 58, 58, 0.2);
  line-height: 0; }

a.thumbnail {
  transition: box-shadow 200ms ease-out; }
  a.thumbnail:hover, a.thumbnail:focus {
    box-shadow: 0 0 6px 1px rgba(130, 65, 153, 0.5); }
  a.thumbnail image {
    box-shadow: none; }

.title-bar {
  padding: 0.5rem;
  background: #3A3A3A;
  color: #FFFFFF;
  display: flex;
  justify-content: flex-start;
  align-items: center; }
  .title-bar .menu-icon {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }

.title-bar-left,
.title-bar-right {
  flex: 1 1 0px; }

.title-bar-right {
  text-align: right; }

.title-bar-title {
  display: inline-block;
  vertical-align: middle;
  font-weight: bold; }

.has-tip {
  position: relative;
  display: inline-block;
  border-bottom: dotted 1px #727171;
  font-weight: bold;
  cursor: help; }

.tooltip {
  position: absolute;
  top: calc(100% + 0.6495rem);
  z-index: 1200;
  max-width: 10rem;
  padding: 0.75rem;
  border-radius: 0;
  background-color: #3A3A3A;
  font-size: 80%;
  color: #FFFFFF; }
  .tooltip::before {
    position: absolute; }
  .tooltip.bottom::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-top-width: 0;
    border-bottom-style: solid;
    border-color: transparent transparent #3A3A3A;
    bottom: 100%; }
  .tooltip.bottom.align-center::before {
    left: 50%;
    transform: translateX(-50%); }
  .tooltip.top::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #3A3A3A transparent transparent;
    top: 100%;
    bottom: auto; }
  .tooltip.top.align-center::before {
    left: 50%;
    transform: translateX(-50%); }
  .tooltip.left::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #3A3A3A;
    left: 100%; }
  .tooltip.left.align-center::before {
    bottom: auto;
    top: 50%;
    transform: translateY(-50%); }
  .tooltip.right::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #3A3A3A transparent transparent;
    right: 100%;
    left: auto; }
  .tooltip.right.align-center::before {
    bottom: auto;
    top: 50%;
    transform: translateY(-50%); }
  .tooltip.align-top::before {
    bottom: auto;
    top: 10%; }
  .tooltip.align-bottom::before {
    bottom: 10%;
    top: auto; }
  .tooltip.align-left::before {
    left: 10%;
    right: auto; }
  .tooltip.align-right::before {
    left: auto;
    right: 10%; }

.top-bar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  flex-wrap: wrap; }
  .top-bar,
  .top-bar ul {
    background-color: #e6e6e6; }
  .top-bar input {
    max-width: 200px;
    margin-right: 1rem; }
  .top-bar .input-group-field {
    width: 100%;
    margin-right: 0; }
  .top-bar input.button {
    width: auto; }
  .top-bar .top-bar-left,
  .top-bar .top-bar-right {
    flex: 0 0 100%;
    max-width: 100%; }
  @media print, screen and (min-width: 40em) {
    .top-bar {
      flex-wrap: nowrap; }
      .top-bar .top-bar-left {
        flex: 1 1 auto;
        margin-right: auto; }
      .top-bar .top-bar-right {
        flex: 0 1 auto;
        margin-left: auto; } }
  @media screen and (max-width: 63.9375em) {
    .top-bar.stacked-for-medium {
      flex-wrap: wrap; }
      .top-bar.stacked-for-medium .top-bar-left,
      .top-bar.stacked-for-medium .top-bar-right {
        flex: 0 0 100%;
        max-width: 100%; } }
  @media screen and (max-width: 74.9375em) {
    .top-bar.stacked-for-large {
      flex-wrap: wrap; }
      .top-bar.stacked-for-large .top-bar-left,
      .top-bar.stacked-for-large .top-bar-right {
        flex: 0 0 100%;
        max-width: 100%; } }

.top-bar-title {
  flex: 0 0 auto;
  margin: 0.5rem 1rem 0.5rem 0; }

.top-bar-left,
.top-bar-right {
  flex: 0 0 auto; }

.hide {
  display: none !important; }

.invisible {
  visibility: hidden; }

@media screen and (max-width: 39.9375em) {
  .hide-for-small-only {
    display: none !important; } }

@media screen and (max-width: 0em), screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important; } }

@media print, screen and (min-width: 40em) {
  .hide-for-medium {
    display: none !important; } }

@media screen and (max-width: 39.9375em) {
  .show-for-medium {
    display: none !important; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .hide-for-medium-only {
    display: none !important; } }

@media screen and (max-width: 39.9375em), screen and (min-width: 64em) {
  .show-for-medium-only {
    display: none !important; } }

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important; } }

@media screen and (max-width: 63.9375em) {
  .show-for-large {
    display: none !important; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .hide-for-large-only {
    display: none !important; } }

@media screen and (max-width: 63.9375em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important; } }

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.show-for-landscape,
.hide-for-portrait {
  display: block !important; }
  @media screen and (orientation: landscape) {
    .show-for-landscape,
    .hide-for-portrait {
      display: block !important; } }
  @media screen and (orientation: portrait) {
    .show-for-landscape,
    .hide-for-portrait {
      display: none !important; } }

.hide-for-landscape,
.show-for-portrait {
  display: none !important; }
  @media screen and (orientation: landscape) {
    .hide-for-landscape,
    .show-for-portrait {
      display: none !important; } }
  @media screen and (orientation: portrait) {
    .hide-for-landscape,
    .show-for-portrait {
      display: block !important; } }

.align-right {
  justify-content: flex-end; }

.align-center {
  justify-content: center; }

.align-justify {
  justify-content: space-between; }

.align-spaced {
  justify-content: space-around; }

.align-right.vertical.menu > li > a {
  justify-content: flex-end; }

.align-center.vertical.menu > li > a {
  justify-content: center; }

.align-top {
  align-items: flex-start; }

.align-self-top {
  align-self: flex-start; }

.align-bottom {
  align-items: flex-end; }

.align-self-bottom {
  align-self: flex-end; }

.align-middle {
  align-items: center; }

.align-self-middle {
  align-self: center; }

.align-stretch {
  align-items: stretch; }

.align-self-stretch {
  align-self: stretch; }

.align-center-middle {
  justify-content: center;
  align-items: center;
  align-content: center; }

.small-order-1 {
  order: 1; }

.small-order-2 {
  order: 2; }

.small-order-3 {
  order: 3; }

.small-order-4 {
  order: 4; }

.small-order-5 {
  order: 5; }

.small-order-6 {
  order: 6; }

@media print, screen and (min-width: 40em) {
  .medium-order-1 {
    order: 1; }
  .medium-order-2 {
    order: 2; }
  .medium-order-3 {
    order: 3; }
  .medium-order-4 {
    order: 4; }
  .medium-order-5 {
    order: 5; }
  .medium-order-6 {
    order: 6; } }

@media print, screen and (min-width: 64em) {
  .large-order-1 {
    order: 1; }
  .large-order-2 {
    order: 2; }
  .large-order-3 {
    order: 3; }
  .large-order-4 {
    order: 4; }
  .large-order-5 {
    order: 5; }
  .large-order-6 {
    order: 6; } }

.flex-container {
  display: flex; }

.flex-child-auto {
  flex: 1 1 auto; }

.flex-child-grow {
  flex: 1 0 auto; }

.flex-child-shrink {
  flex: 0 1 auto; }

.flex-dir-row {
  flex-direction: row; }

.flex-dir-row-reverse {
  flex-direction: row-reverse; }

.flex-dir-column {
  flex-direction: column; }

.flex-dir-column-reverse {
  flex-direction: column-reverse; }

@media print, screen and (min-width: 40em) {
  .medium-flex-container {
    display: flex; }
  .medium-flex-child-auto {
    flex: 1 1 auto; }
  .medium-flex-child-grow {
    flex: 1 0 auto; }
  .medium-flex-child-shrink {
    flex: 0 1 auto; }
  .medium-flex-dir-row {
    flex-direction: row; }
  .medium-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .medium-flex-dir-column {
    flex-direction: column; }
  .medium-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

@media print, screen and (min-width: 64em) {
  .large-flex-container {
    display: flex; }
  .large-flex-child-auto {
    flex: 1 1 auto; }
  .large-flex-child-grow {
    flex: 1 0 auto; }
  .large-flex-child-shrink {
    flex: 0 1 auto; }
  .large-flex-dir-row {
    flex-direction: row; }
  .large-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .large-flex-dir-column {
    flex-direction: column; }
  .large-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

.slide-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(-100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-down.mui-enter.mui-enter-active {
  transform: translateY(0); }

.slide-in-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(-100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-left.mui-enter.mui-enter-active {
  transform: translateX(0); }

.slide-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-up.mui-enter.mui-enter-active {
  transform: translateY(0); }

.slide-in-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-right.mui-enter.mui-enter-active {
  transform: translateX(0); }

.slide-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-down.mui-leave.mui-leave-active {
  transform: translateY(100%); }

.slide-out-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-right.mui-leave.mui-leave-active {
  transform: translateX(100%); }

.slide-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-up.mui-leave.mui-leave-active {
  transform: translateY(-100%); }

.slide-out-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-left.mui-leave.mui-leave-active {
  transform: translateX(-100%); }

.fade-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 0;
  transition-property: opacity; }

.fade-in.mui-enter.mui-enter-active {
  opacity: 1; }

.fade-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 1;
  transition-property: opacity; }

.fade-out.mui-leave.mui-leave-active {
  opacity: 0; }

.hinge-in-from-top.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  transform-origin: top;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-top.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  transform-origin: right;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-right.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-bottom.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(90deg);
  transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-bottom.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(90deg);
  transform-origin: left;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-left.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-middle-x.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-middle-x.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-middle-y.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-middle-y.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-out-from-top.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: top;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-top.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0; }

.hinge-out-from-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: right;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-right.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0; }

.hinge-out-from-bottom.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-bottom.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(90deg);
  opacity: 0; }

.hinge-out-from-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: left;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-left.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(90deg);
  opacity: 0; }

.hinge-out-from-middle-x.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-middle-x.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0; }

.hinge-out-from-middle-y.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-middle-y.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0; }

.scale-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(0.5);
  transition-property: transform, opacity;
  opacity: 0; }

.scale-in-up.mui-enter.mui-enter-active {
  transform: scale(1);
  opacity: 1; }

.scale-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1.5);
  transition-property: transform, opacity;
  opacity: 0; }

.scale-in-down.mui-enter.mui-enter-active {
  transform: scale(1);
  opacity: 1; }

.scale-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1; }

.scale-out-up.mui-leave.mui-leave-active {
  transform: scale(1.5);
  opacity: 0; }

.scale-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1; }

.scale-out-down.mui-leave.mui-leave-active {
  transform: scale(0.5);
  opacity: 0; }

.spin-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(-0.75turn);
  transition-property: transform, opacity;
  opacity: 0; }

.spin-in.mui-enter.mui-enter-active {
  transform: rotate(0);
  opacity: 1; }

.spin-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1; }

.spin-out.mui-leave.mui-leave-active {
  transform: rotate(0.75turn);
  opacity: 0; }

.spin-in-ccw.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0.75turn);
  transition-property: transform, opacity;
  opacity: 0; }

.spin-in-ccw.mui-enter.mui-enter-active {
  transform: rotate(0);
  opacity: 1; }

.spin-out-ccw.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1; }

.spin-out-ccw.mui-leave.mui-leave-active {
  transform: rotate(-0.75turn);
  opacity: 0; }

.slow {
  transition-duration: 750ms !important; }

.fast {
  transition-duration: 250ms !important; }

.linear {
  transition-timing-function: linear !important; }

.ease {
  transition-timing-function: ease !important; }

.ease-in {
  transition-timing-function: ease-in !important; }

.ease-out {
  transition-timing-function: ease-out !important; }

.ease-in-out {
  transition-timing-function: ease-in-out !important; }

.bounce-in {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }

.bounce-out {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }

.bounce-in-out {
  transition-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }

.short-delay {
  transition-delay: 300ms !important; }

.long-delay {
  transition-delay: 700ms !important; }

.shake {
  animation-name: shake-7; }

@keyframes shake-7 {
  0%, 10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% {
    transform: translateX(7%); }
  5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% {
    transform: translateX(-7%); } }

.spin-cw {
  animation-name: spin-cw-1turn; }

@keyframes spin-cw-1turn {
  0% {
    transform: rotate(-1turn); }
  100% {
    transform: rotate(0); } }

.spin-ccw {
  animation-name: spin-cw-1turn; }

@keyframes spin-cw-1turn {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(1turn); } }

.wiggle {
  animation-name: wiggle-7deg; }

@keyframes wiggle-7deg {
  40%, 50%, 60% {
    transform: rotate(7deg); }
  35%, 45%, 55%, 65% {
    transform: rotate(-7deg); }
  0%, 30%, 70%, 100% {
    transform: rotate(0); } }

.shake,
.spin-cw,
.spin-ccw,
.wiggle {
  animation-duration: 500ms; }

.infinite {
  animation-iteration-count: infinite; }

.slow {
  animation-duration: 750ms !important; }

.fast {
  animation-duration: 250ms !important; }

.linear {
  animation-timing-function: linear !important; }

.ease {
  animation-timing-function: ease !important; }

.ease-in {
  animation-timing-function: ease-in !important; }

.ease-out {
  animation-timing-function: ease-out !important; }

.ease-in-out {
  animation-timing-function: ease-in-out !important; }

.bounce-in {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }

.bounce-out {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }

.bounce-in-out {
  animation-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }

.short-delay {
  animation-delay: 300ms !important; }

.long-delay {
  animation-delay: 700ms !important; }

.hero.news {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right top; }
  .hero.news .hero-animate {
    background-image: url("../img/news/hero.jpg"); }
  @media screen and (max-width: 63.9375em) {
    .hero.news .job-meta span {
      font-size: 16px;
      padding-right: 10px; } }
  @media screen and (max-width: 39.9375em) {
    .hero.news {
      background-position: center center; } }

.past span {
  opacity: 0.2; }

.overlay-fade {
  background-position: bottom center;
  background-repeat: repeat-x;
  position: absolute;
  width: 100%;
  height: 100%; }
  .overlay-fade.purple {
    background-image: url("../img/global/purple-overlay.png"); }
  .overlay-fade.teal {
    background-image: url("../img/global/teal-overlay.png"); }

.posts {
  padding: 60px 20px 120px 20px;
  margin: 0 auto; }
  .posts .line-image {
    margin-top: -201px;
    position: relative;
    z-index: 999; }

.post {
  width: 100%;
  min-height: 350px;
  position: relative;
  background-color: #824199;
  margin-bottom: 15px; }
  .post.event {
    background: #8AD1D3; }
  .post.tall {
    min-height: 400px; }

.preview {
  position: absolute;
  height: 100%;
  width: 100%;
  background: -webkit-gradient(linear, right bottom, left bottom, color-stop(0.32, #8F4DA7), color-stop(0.65, #713D9F)) !important;
  background: -o-linear-gradient(left, #8F4DA7 32%, #713D9F 65%) !important;
  background: -moz-linear-gradient(left, #8F4DA7 32%, #713D9F 65%) !important;
  background: -webkit-linear-gradient(left, #8F4DA7 32%, #713D9F 65%) !important;
  background: -ms-linear-gradient(left, #8F4DA7 32%, #713D9F 65%) !important;
  background: linear-gradient(to left, #8F4DA7 32%, #713D9F 65%) !important;
  display: none; }
  .preview p {
    color: #ffffff;
    padding: 30px; }
  .preview.teal {
    background: -webkit-gradient(linear, right bottom, left bottom, color-stop(0.32, #8AD1D3), color-stop(0.65, #52bbbe)) !important;
    background: -o-linear-gradient(left, #8AD1D3 32%, #52bbbe 65%) !important;
    background: -moz-linear-gradient(left, #8AD1D3 32%, #52bbbe 65%) !important;
    background: -webkit-linear-gradient(left, #8AD1D3 32%, #52bbbe 65%) !important;
    background: -ms-linear-gradient(left, #8AD1D3 32%, #52bbbe 65%) !important;
    background: linear-gradient(to left, #8AD1D3 32%, #52bbbe 65%) !important; }

.post-meta {
  position: absolute;
  bottom: 0px;
  color: #ffffff;
  left: 0;
  right: 0;
  max-width: 80%;
  margin: 0 auto 25px auto;
  border-left: 1px solid #ffffff;
  padding-left: 20px;
  padding-top: 15px; }
  .post-meta .title {
    font-size: 18px;
    line-height: 28px;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-style: normal;
    font-weight: 500; }
  .post-meta .date {
    margin-bottom: 0px; }

a.expand {
  background-color: #ffffff;
  display: block;
  position: relative;
  width: 100%;
  border: 1px solid #824199;
  outline: 0;
  height: 50px;
  line-height: 50px;
  padding-left: 30px;
  text-transform: uppercase;
  margin-bottom: 25px;
  margin-top: -30px;
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-style: normal;
  font-weight: 500; }
  a.expand.read-more::after {
    font-family: 'Material-Design-Iconic-Font';
    content: '\f2f1';
    position: absolute;
    font-size: 24px;
    right: 30px; }
  a.expand.read-more:hover {
    background: #643275;
    color: #ffffff; }
  a.expand.watch::after {
    font-family: 'Material-Design-Iconic-Font';
    content: '\f3aa';
    position: absolute;
    font-size: 24px;
    right: 30px; }
  a.expand.teal {
    color: #8AD1D3;
    border: 1px solid #8AD1D3; }
    a.expand.teal:hover {
      background: #52bbbe; }

.button-row .button {
  max-width: 100%;
  margin-top: 60px;
  font-size: 15px;
  height: 50px;
  padding: 18px 1em;
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-style: normal;
  font-weight: 500; }

.hero-search {
  border: 2px solid #ffffff;
  border-radius: 5px;
  height: 51px;
  width: 580px; }
  .hero-search input[type=text] {
    background: none;
    color: #ffffff;
    font-size: 14px;
    height: 50px;
    border: none;
    width: 240px;
    box-shadow: none;
    font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-style: normal;
    font-weight: 400; }
  .hero-search .input-group-label {
    background: none;
    border: none; }
    .hero-search .input-group-label i {
      color: #ffffff; }
  .hero-search button, .hero-search input[type=submit] {
    width: 100%;
    max-width: 100%; }
  .hero-search .input-group-button {
    width: 289px; }
    .hero-search .input-group-button button, .hero-search .input-group-button input[type=submit] {
      border: none;
      box-shadow: none;
      border-radius-top-right: 5px;
      border-radius-bottom-right: 5px;
      text-transform: uppercase;
      letter-spacing: 1px; }
  @media screen and (max-width: 63.9375em) {
    .hero-search {
      width: 480px; }
      .hero-search .input-group-button {
        width: 190px; }
        .hero-search .input-group-button button, .hero-search .input-group-button input[type=submit] {
          font-size: 14px; } }
  @media screen and (max-width: 39.9375em) {
    .hero-search {
      max-width: 300px; }
      .hero-search .ph-label, .hero-search input[type=text] {
        width: 100%; }
      .hero-search .input-group-button {
        display: none; } }
  @media (max-width: 400px) {
    .hero-search {
      max-width: 280px; } }

.article {
  border-top: 2px solid #CFD1D7;
  border-bottom: 2px solid #CFD1D7;
  margin-bottom: 30px;
  padding-top: 40px;
  padding-bottom: 40px;
  color: #824199;
  position: relative;
  display: none; }
  .article .article-meta {
    max-width: 200px; }
    .article .article-meta span {
      display: block;
      padding-bottom: 8px;
      border-bottom: 1px solid #824199;
      font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 10px; }
    .article .article-meta .tag::before {
      font-family: 'Material-Design-Iconic-Font';
      content: '\f1ab';
      padding-right: 8px;
      font-size: 16px; }
    .article .article-meta .article-tag::before {
      font-family: 'Material-Design-Iconic-Font';
      content: '\f158';
      padding-right: 8px;
      font-size: 16px; }
    .article .article-meta .date::before {
      font-family: 'Material-Design-Iconic-Font';
      content: '\f332';
      padding-right: 8px;
      font-size: 16px; }
  .article .title {
    color: #824199;
    font-size: 24px;
    text-transform: uppercase;
    font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 40px;
    margin-right: 50px; }
    @media screen and (max-width: 63.9375em) {
      .article .title {
        margin-right: 0px; } }
  .article .content {
    color: #979797;
    font-size: 18px; }
    .article .content img {
      max-width: 100%;
      width: 100%;
      height: auto;
      margin: 20px 0; }
    .article .content p {
      font-size: 18px;
      color: #979797; }
  .article .article-close::after {
    font-family: 'Material-Design-Iconic-Font';
    position: absolute;
    content: '\f135';
    color: #824199;
    font-size: 32px;
    right: 20px;
    top: 32px; }
  .article .social-share {
    margin-top: 20px; }
    .article .social-share a.social-button {
      background-color: #824199;
      width: 28px;
      height: 28px;
      line-height: 28px;
      font-size: 14px;
      margin-right: 3px; }
      .article .social-share a.social-button:hover {
        background-color: #643275; }
  @media screen and (max-width: 63.9375em) {
    .article .content p {
      font-size: 16px; } }
  @media screen and (max-width: 39.9375em) {
    .article {
      padding-top: 20px; }
      .article .content p {
        font-size: 14px;
        line-height: 28px; }
      .article .article-close::after {
        display: block;
        position: relative;
        margin-bottom: 50px;
        float: right;
        clear: both; }
      .article .article-meta {
        max-width: 100%;
        margin-bottom: 40px; }
      .article .title {
        font-size: 21px;
        margin-bottom: 30px; } }

.campus-event {
  margin-bottom: 20px; }

.home-slider {
  /*
	#type-container {
		height: rem-calc(200);
	} 
	*/ }
  .home-slider .slide-one, .home-slider .slide-two, .home-slider .slide-three, .home-slider .slide-four {
    height: 650px;
    overflow: hidden; }
    @media screen and (min-width: 40em) {
      .home-slider .slide-one, .home-slider .slide-two, .home-slider .slide-three, .home-slider .slide-four {
        height: 800px; } }
  .home-slider .slide-one {
    background: url("../img/home/home-slides-1-2200-over20-tiny.jpg");
    background-size: cover;
    background-position: bottom center; }
  .home-slider .slide-two {
    background: url("../img/home/home-slides-2-2200-over20-tiny.jpg");
    background-size: cover;
    background-position: center center; }
  .home-slider .slide-three {
    background: url("../img/home/home-slides-3-2200-over20-tiny.jpg");
    background-size: cover;
    background-position: center center; }
  .home-slider .slide-four {
    background: url("../img/home/home-slides-4-2200-over20-tiny.jpg");
    background-size: cover;
    background-position: center center; }
  .home-slider .line {
    width: 0.125rem;
    background: #D8D8D8; }
    .home-slider .line.short {
      height: 1.25rem; }
      @media screen and (min-width: 40em) {
        .home-slider .line.short {
          height: 5rem; } }
    .home-slider .line.long {
      height: 2.5rem; }
      @media screen and (min-width: 40em) {
        .home-slider .line.long {
          height: 8.75rem; } }
  .home-slider .top-container {
    height: 250px; }
    @media screen and (min-width: 40em) {
      .home-slider .top-container {
        height: 31.25rem; } }
    .home-slider .top-container p {
      color: #FFFFFF;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 0.9375rem;
      letter-spacing: 0.15625rem;
      margin-left: -2.5rem;
      margin-top: 0.625rem; }
    .home-slider .top-container .center {
      width: 100%; }
  .home-slider .bottom-container {
    height: 25rem;
    padding: 0 1.25rem;
    background: rgba(238, 238, 238, 0.85); }
    @media screen and (min-width: 40em) {
      .home-slider .bottom-container {
        height: 18.75rem; } }
    .home-slider .bottom-container a {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      transition: all ease 0.3s; }
      .home-slider .bottom-container a:hover {
        opacity: 0.7; }
    .home-slider .bottom-container i {
      font-size: 2.1875rem;
      color: #FFFFFF; }
    .home-slider .bottom-container p {
      margin-bottom: 0;
      position: relative; }
      @media screen and (max-width: 39.9375em) {
        .home-slider .bottom-container p {
          line-height: 28px; } }
    .home-slider .bottom-container img {
      position: absolute;
      right: 6%;
      bottom: -120px;
      opacity: 0.5; }
    .home-slider .bottom-container .row {
      position: relative; }
  .home-slider h1 {
    color: #FFFFFF;
    white-space: normal;
    font-weight: 700;
    letter-spacing: 0.375rem;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    text-transform: uppercase;
    display: inline;
    vertical-align: top; }
    @media screen and (min-width: 40em) and (max-width: 63.9375em) {
      .home-slider h1 {
        line-height: 63px; } }
    @media screen and (min-width: 64em) {
      .home-slider h1 {
        font-size: 3.75rem;
        line-height: 5.3125rem;
        margin-left: -2.5rem;
        white-space: pre; } }
    .home-slider h1 span {
      background-image: linear-gradient(-208deg, #824199 23%, rgba(255, 255, 255, 0) 147%);
      padding: 5px 20px 0 0; }
      @media screen and (min-width: 64em) {
        .home-slider h1 span {
          margin-left: -2.5rem;
          padding: 5px 20px 15px; } }

.slick-dots {
  margin: 0;
  list-style: none;
  text-align: center;
  display: block;
  margin-top: -52px;
  position: relative; }
  .slick-dots li {
    display: inline;
    margin: 0 5px; }
    .slick-dots li button {
      color: #979797;
      cursor: pointer; }
  .slick-dots .slick-active button {
    color: #824199; }

.center-container {
  position: relative; }
  .center-container .center {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%; }

@media screen and (max-width: 63.9375em) {
  .bottom-container.center-container .center {
    position: relative;
    top: 0;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none; }
    .bottom-container.center-container .center p {
      font-size: 14px;
      padding-top: 30px; } }

.listing.hero {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right top; }
  .listing.hero .hero-animate {
    background-image: url("../img/listing/hero.jpg"); }
  .listing.hero.software .hero-animate {
    background-image: url("../img/listing/software-hero.jpg"); }
  .listing.hero.other .hero-animate {
    background-image: url("../img/listing/other-hero.jpg"); }
  .listing.hero.quant .hero-animate {
    background-image: url("../img/listing/quant-hero.jpg"); }
  .listing.hero.trading .hero-animate {
    background-image: url("../img/listing/trading-hero.jpg"); }
  .listing.hero.infra .hero-animate {
    background-image: url("../img/listing/infra-hero.jpg"); }
  @media screen and (max-width: 63.9375em) {
    .listing.hero .job-meta span {
      font-size: 16px;
      padding-right: 10px; } }
  @media screen and (max-width: 39.9375em) {
    .listing.hero {
      background-position: 70%; } }

.wrapper.listing {
  padding-left: 20px;
  padding-right: 20px; }
  @media screen and (max-width: 39.9375em) {
    .wrapper.listing {
      padding-top: 60px;
      padding-bottom: 60px; } }

.listing {
  position: relative; }
  .listing .main {
    float: right;
    z-index: 2; }
  .listing .side {
    float: right;
    position: relative;
    z-index: 2; }
  .listing .side-nav {
    max-width: 200px;
    margin-left: auto;
    margin-right: 0; }
    .listing .side-nav .apply {
      font-size: 18px;
      line-height: 22px; }
  @media screen and (max-width: 63.9375em) {
    .listing .side-nav {
      max-width: 100%;
      margin-bottom: 50px;
      clear: both;
      float: none; }
      .listing .side-nav .apply {
        font-size: 16px; }
      .listing .side-nav .button {
        max-width: 100%;
        display: block; } }
  @media screen and (max-width: 39.9375em) {
    .listing .side-nav .apply {
      font-size: 16px; } }
  .listing .section {
    margin-bottom: 70px;
    color: #979797;
    font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-style: normal;
    font-weight: 400; }
    .listing .section h2 {
      color: #824199;
      font-size: 24px;
      margin-bottom: 20px;
      text-transform: uppercase;
      font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
      font-style: normal;
      font-weight: 500; }
    .listing .section p {
      color: #979797; }
    .listing .section .copy {
      padding-left: 80px;
      font-size: 18px; }
    @media screen and (max-width: 63.9375em) {
      .listing .section h2 {
        font-size: 20px; }
      .listing .section .copy {
        padding-left: 30px;
        font-size: 16px; }
        .listing .section .copy .copy p {
          font-size: 16px; } }
    @media screen and (max-width: 39.9375em) {
      .listing .section h2 {
        font-size: 16px;
        line-height: 24px; }
      .listing .section .copy {
        padding-left: 20px;
        font-size: 14px;
        line-height: 26px; }
        .listing .section .copy p {
          line-height: 26px; } }

.job-meta span {
  color: #ffffff;
  padding-right: 30px;
  font-size: 18px;
  display: inline-block; }
  .job-meta span:last-child {
    padding-right: 0px; }

.ctc-callout {
  background: transparent;
  position: relative;
  color: #824199;
  opacity: .2;
  font-weight: bold;
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  text-transform: uppercase;
  font-size: 72px;
  text-align: right;
  position: absolute;
  bottom: -30px;
  right: 0px;
  letter-spacing: 6px;
  line-height: 76px;
  padding-right: 20px;
  z-index: 1;
  display: none; }
  @media screen and (max-width: 63.9375em) {
    .ctc-callout {
      font-size: 54px;
      line-height: 56px; } }
  @media screen and (max-width: 39.9375em) {
    .ctc-callout {
      font-size: 38px;
      line-height: 42px;
      position: relative;
      bottom: 0px; } }
  @media (max-width: 400px) {
    .ctc-callout {
      font-size: 28px;
      line-height: 32px;
      margin: 0 auto;
      left: 0;
      right: 0;
      padding: 0; } }

.footer-wrapper .medium-8 .row .columns:last-child ul li a {
  text-transform: uppercase;
  color: #824199; }

.hero.about .hero-animate {
  background: url("../img/about/whoweare-hero-1-2200-over20-tiny.jpg") center center/cover no-repeat #643275; }

.color-fix {
  width: 100%;
  height: 300px;
  background: #ddf1f3;
  position: absolute; }

#subject {
  background-color: #EEEEEE;
  border: 1px solid #EEEEEE; }

.about--intro {
  padding: 3.75rem 0;
  position: relative; }
  .about--intro .stock--container {
    margin: 6.25rem auto; }
    .about--intro .stock--container p {
      margin-bottom: 0; }
  .about--intro .line-image {
    width: 50%;
    position: relative;
    margin-top: -11.25rem;
    z-index: 999; }
  .about--intro .stock-name {
    text-transform: uppercase;
    font-size: 0.9375rem;
    font-weight: 500;
    letter-spacing: 0.15625rem;
    display: inline; }
  .about--intro .stock-percentage {
    display: inline;
    float: right;
    color: #727171;
    font-size: 0.875rem; }
  .about--intro .stock-price {
    color: #727171;
    font-weight: 200;
    font-size: 2.25rem;
    float: right; }
  .about--intro i {
    font-size: 2.25rem; }
  .about--intro hr {
    margin: 0.625rem 0; }
  .about--intro .purple .stock-name {
    color: #824199; }
  .about--intro .purple hr {
    border-color: #824199; }
  .about--intro .purple i {
    color: #824199; }
  .about--intro .yellow .stock-name {
    color: #FAE46C; }
  .about--intro .yellow hr {
    border-color: #FAE46C; }
  .about--intro .yellow i {
    color: #FAE46C; }
  .about--intro .orange .stock-name {
    color: #FCA150; }
  .about--intro .orange hr {
    border-color: #FCA150; }
  .about--intro .orange i {
    color: #FCA150; }
  .about--intro .teal .stock-name {
    color: #8AD1D3; }
  .about--intro .teal hr {
    border-color: #8AD1D3; }
  .about--intro .teal i {
    color: #8AD1D3; }
  .about--intro .graph {
    position: absolute;
    bottom: 0;
    width: 100%; }

.about--locations .location--container {
  height: 21.875rem;
  position: relative;
  overflow: hidden; }
  @media screen and (min-width: 40em) {
    .about--locations .location--container {
      height: 500px; } }
  .about--locations .location--container:hover .inner {
    opacity: 1; }

.about--locations video {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 50%;
  /* % of surrounding element */
  top: 50%;
  transform: translate(-50%, -50%);
  /* % of current element */
  height: 100%;
  width: 80.77777778vh;
  /* 100 * 16 / 9 */
  min-width: 100%;
  min-height: 56.25vw;
  /* 100 * 9 / 16 */ }

.about--locations .location-date, .about--locations .location-time {
  font-size: 4.5rem;
  color: #FFFFFF;
  font-weight: 700;
  margin-bottom: 0;
  line-height: 1; }

.about--locations .location-date {
  opacity: 0.6; }

.about--locations .inner {
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: all ease 0.3s;
  padding-top: 30px; }
  @media screen and (min-width: 40em) {
    .about--locations .inner {
      padding-top: 0; } }
  .about--locations .inner .line {
    display: block;
    width: 0.0625rem;
    margin-left: 2.5rem; }
    .about--locations .inner .line.purple {
      background: #824199; }
    .about--locations .inner .line.long {
      height: 6.875rem; }
    .about--locations .inner .line.short {
      height: 5rem; }
  .about--locations .inner a {
    display: block;
    color: #FFFFFF;
    font-size: 1.125rem;
    line-height: 1.875rem;
    margin-bottom: 0.9375rem;
    font-weight: 400;
    transition: all ease 0.3s; }
    .about--locations .inner a:hover {
      color: #824199; }
  .about--locations .inner h2 {
    line-height: 1.7;
    color: #FFFFFF;
    margin-bottom: 0;
    margin-bottom: 0.625rem; }
    @media screen and (min-width: 40em) {
      .about--locations .inner h2 {
        margin-bottom: 0; } }
  .about--locations .inner .address:before {
    content: 'A';
    color: #FFFFFF;
    opacity: 0.6;
    font-weight: 500;
    margin-right: 0.625rem; }
  .about--locations .inner .address span {
    padding-left: 1.25rem; }
  .about--locations .inner .phone:before {
    content: 'P';
    color: #FFFFFF;
    opacity: 0.6;
    font-weight: 500;
    margin-right: 0.625rem; }
  .about--locations .inner .phone span {
    padding-left: 1.25rem; }
  .about--locations .inner .fax:before {
    content: 'F';
    color: #FFFFFF;
    opacity: 0.6;
    font-weight: 500;
    margin-right: 0.625rem; }
  .about--locations .inner .email:before {
    content: 'E';
    color: #FFFFFF;
    opacity: 0.6;
    font-weight: 500;
    margin-right: 0.625rem; }

.about--contact {
  padding: 4.375rem 0; }
  .about--contact h2 {
    margin-top: 1.25rem; }
  .about--contact form {
    margin-top: 1.875rem; }
  .about--contact label {
    text-transform: uppercase;
    color: #824199;
    font-size: 0.875rem;
    line-height: 1.625rem;
    font-weight: 400; }
  .about--contact textarea {
    padding: 0.9375rem 0.9375rem;
    margin-bottom: 0; }
  .about--contact input {
    padding: 0.3125rem 0.9375rem; }
  .about--contact textarea, .about--contact input {
    border: none;
    background: #EEEEEE;
    box-shadow: none;
    transition: all ease 0.3s;
    font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    color: #979797; }
    .about--contact textarea:focus, .about--contact input:focus {
      background: #EEEEEE;
      border: none;
      color: #3A3A3A;
      box-shadow: none; }
  .about--contact button {
    background: #824199;
    color: #FFFFFF;
    text-transform: uppercase;
    font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    width: 100%;
    font-size: 0.9375rem;
    padding: 0.625rem;
    letter-spacing: 0.15625rem;
    transition: all ease 0.3s;
    cursor: pointer;
    margin-top: 3.125rem; }
    @media screen and (min-width: 40em) {
      .about--contact button {
        width: 50%; } }
    .about--contact button:hover {
      opacity: 0.7; }

.about--cta {
  background: url("../img/about/whoweare-cta-banner-1-2200-over20-tiny.jpg") center center/cover no-repeat;
  padding: 6.25rem 0;
  text-align: center; }
  .about--cta .button {
    font-size: 0.875rem !important;
    border-radius: 0;
    max-width: 30rem;
    letter-spacing: 0.21313rem;
    font-size: 1.125rem;
    margin: auto;
    color: #FFFFFF !important; }
    @media screen and (min-width: 40em) {
      .about--cta .button {
        font-size: 1.125rem !important; } }

.hero.careers {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom; }
  .hero.careers .hero-animate {
    background-image: url("../img/careers/hero.jpg"); }
  @media screen and (max-width: 63.9375em) {
    .hero.careers .job-meta span {
      font-size: 16px;
      padding-right: 10px; } }
  @media screen and (max-width: 39.9375em) {
    .hero.careers {
      background-position: center center; } }

.purple {
  color: #824199 !important; }

.gray-copy {
  color: #727171 !important; }

.wrapper.career {
  padding-left: 20px;
  padding-right: 20px; }

.career .category-tags {
  margin-top: 50px;
  max-width: 455px; }

.career .job-category {
  position: relative;
  margin: 60px 0px 100px 90px; }
  .career .job-category .img {
    position: relative; }
  .career .job-category .content-wrapper {
    margin-top: 60px; }
    @media screen and (max-width: 39.9375em) {
      .career .job-category .content-wrapper {
        margin-top: 30px; } }
  .career .job-category .copy {
    color: #727171;
    font-size: 16px;
    font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    max-width: 360px; }
  .career .job-category .category-tag {
    padding: 8px 50px;
    text-transform: uppercase;
    margin-bottom: 10px;
    display: block;
    position: relative;
    font-size: 14px;
    font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-style: normal;
    font-weight: 500; }
    .career .job-category .category-tag.overflow {
      z-index: 10; }
  .career .job-category .category-tag.orange-bg,
  .career .job-category .category-tag.teal-bg {
    color: #ffffff; }
  .career .job-category .category-tag.yellow-bg {
    color: #6B6B6B; }
  .career .job-category img {
    width: 100%;
    height: auto; }
  .career .job-category .overlay {
    padding: 45px;
    background-color: rgba(137, 50, 168, 0.94);
    margin: 0 auto;
    color: #ffffff; }
    .career .job-category .overlay .content {
      display: inline-block;
      border-left: 1px solid #ffffff;
      border-right: 1px solid #ffffff;
      padding-left: 20px;
      padding-right: 20px; }
    .career .job-category .overlay h3 {
      font-weight: bold;
      margin-bottom: 0px;
      font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
      font-size: 26px;
      text-transform: uppercase;
      letter-spacing: 3px; }
    .career .job-category .overlay p {
      font-size: 14px;
      margin-bottom: 0;
      line-height: 18px;
      font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
      font-style: normal;
      font-weight: 400; }

.career .job-category:nth-child(even) .category-tag:nth-child(even) {
  padding-left: 90px;
  margin-left: -47px; }

.career .job-category:nth-child(even) .overlay {
  padding-bottom: 22px;
  position: absolute;
  left: 60px;
  bottom: -40px; }
  @media screen and (min-width: 40em) {
    .career .job-category:nth-child(even) .overlay {
      padding-bottom: 100px; } }

.career .job-category:nth-child(odd) .content-wrapper {
  margin-left: 0px; }

.career .job-category:nth-child(odd) .copy {
  max-width: 430px; }

.career .job-category:nth-child(odd) .category-tags {
  margin-right: 0px;
  margin-left: auto;
  margin-top: 80px; }
  .career .job-category:nth-child(odd) .category-tags .category-tag {
    display: table;
    margin-left: auto;
    margin-right: 0px; }
    .career .job-category:nth-child(odd) .category-tags .category-tag.overflow {
      display: block; }

.career .job-category:nth-child(odd) .overlay {
  padding-bottom: 22px;
  position: absolute;
  right: 40px;
  bottom: -40px; }
  @media screen and (min-width: 40em) {
    .career .job-category:nth-child(odd) .overlay {
      padding-bottom: 70px; } }

.career .job-category:nth-child(odd) .category-tag:nth-child(odd) {
  margin-right: -60px; }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .career .job-category {
    margin: 0; }
  .career .category-tags {
    margin-top: 80px; }
  .career .job-category.right .img {
    margin-bottom: 40px !important;
    margin-top: 50px; } }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) and (min-width: 40em) {
    .career .job-category.right .img {
      margin-bottom: 140px; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .career .job-category.right .columns:first-child {
    padding-left: 20px; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) and (min-width: 64em) {
  .career .job-category:nth-child(odd) {
    margin-bottom: 230px; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .career .job-category:nth-child(odd) .img {
    margin-top: 80px; }
  .career .job-category:nth-child(odd) .copy {
    margin: 0 auto;
    display: block; }
  .career .job-category:nth-child(odd) .category-tags {
    margin-left: auto;
    margin-right: auto;
    display: block; }
  .career .job-category .copy {
    max-width: 400px; } }

@media screen and (max-width: 63.9375em) and (min-width: 64em) {
  .career .job-category {
    margin: 60px 20px 100px 20px; } }

@media screen and (max-width: 39.9375em) {
  .career .job-category {
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .career .job-category .overlay h3 {
    margin-bottom: 8px;
    font-size: 18px; }
  .career .job-category.right .img {
    margin-top: 40px; }
  .career .job-category .copy {
    font-size: 14px; }
    .career .job-category .copy p {
      font-size: 14px; }
  .career .job-category .overlay {
    padding: 30px;
    margin-bottom: 30px; }
  .career .job-category .overlay .content {
    display: block;
    padding-left: 15px;
    padding-right: 15px; }
  .career .job-category .category-tag {
    padding: 15px 20px 11px 20px;
    font-size: 12px;
    margin: 0px;
    margin-bottom: 8px; }
  .career .job-category .content-wrapper {
    margin: 0px;
    margin-top: 60px; } }
  @media screen and (max-width: 39.9375em) and (min-width: 64em) {
    .career .job-category .content-wrapper {
      margin-top: 30px; } }

@media screen and (max-width: 39.9375em) {
  .career .job-category:nth-child(even) .category-tag:nth-child(even) {
    padding-left: 30px;
    margin-left: 0px; } }

@media screen and (max-width: 63.9375em) {
  .copy {
    margin-top: 70px;
    max-width: none !important; } }

.actions {
  margin-top: 50px; }
  .actions hr {
    max-width: 250px;
    border-color: #824199;
    border-width: 2px;
    margin: 15px auto 10px 0px;
    display: none; }
  .actions a.job-label {
    color: #727171; }
  .actions a {
    font-size: 16px;
    color: #727171;
    -webkit-transition: color 0.3s ease-in-out;
    -moz-transition: color 0.3s ease-in-out;
    -o-transition: color 0.3s ease-in-out;
    transition: color 0.3s ease-in-out; }
    .actions a:hover {
      color: #824199; }
  .actions a.events {
    display: none; }
  .actions a.events::before {
    font-family: 'Material-Design-Iconic-Font';
    content: '\f3aa';
    padding-right: 20px;
    display: inline-block;
    font-size: 21px;
    color: #824199; }

.job-count {
  background: #824199;
  display: inline-block;
  margin-right: 10px;
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #ffffff;
  text-align: center;
  height: 35px;
  width: 35px;
  line-height: 35px;
  border-radius: 50%;
  border: 1px solid #824199;
  position: relative; }
  .job-count span {
    color: #ffffff; }
  .job-count:hover {
    background: none; }
    .job-count:hover span {
      visibility: hidden; }
      .job-count:hover span::after {
        content: '\f2fb';
        font-family: 'Material-Design-Iconic-Font';
        color: #824199;
        visibility: visible;
        font-size: 24px;
        position: absolute;
        left: 0;
        right: 0; }

#culture {
  min-height: 500px;
  margin-top: 90px;
  padding-left: 20px;
  padding-right: 20px;
  background-image: url(../img/careers/culture-bg.jpg);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }
  #culture .copy-section {
    margin-top: -62px; }
    #culture .copy-section h2 {
      color: #824199;
      margin-bottom: 30px; }
    #culture .copy-section p {
      color: #3A3A3A;
      font-size: 18px;
      font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
      font-style: normal;
      font-weight: 400; }
  @media screen and (max-width: 63.9375em) {
    #culture .copy-section {
      margin-top: -54px; } }
  @media screen and (max-width: 39.9375em) {
    #culture .copy-section {
      margin-top: -38px; }
      #culture .copy-section h2 {
        font-size: 28px;
        margin-bottom: 20px; }
      #culture .copy-section p {
        font-size: 14px; } }

.copy-section {
  color: #3A3A3A; }
  .copy-section h2 {
    color: #824199;
    font-size: 46px;
    font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 15px; }
  .copy-section p {
    color: #3A3A3A; }
  @media screen and (max-width: 63.9375em) {
    .copy-section h2 {
      font-size: 36px; }
    .copy-section p {
      font-size: 16px; } }
  @media screen and (max-width: 39.9375em) {
    .copy-section h2 {
      font-size: 28px;
      line-height: 36px; }
    .copy-section p {
      font-size: 14px;
      line-height: 28px; } }

#culture-grid {
  padding-top: 60px;
  padding-bottom: 60px; }
  #culture-grid .grid {
    min-height: 380px;
    background-color: #824199;
    margin-bottom: 1.875rem;
    position: relative;
    padding: 40px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center; }
    #culture-grid .grid .copy {
      color: #ffffff;
      font-size: 16px;
      line-height: 30px;
      font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
      font-style: normal;
      font-weight: 400; }
  #culture-grid .title {
    display: none;
    margin: 0 auto;
    padding: 5px 20px;
    border-left: 1px solid #ffffff;
    border-right: 1px solid #ffffff;
    width: max-content;
    left: 0;
    right: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
    #culture-grid .title h3 {
      text-transform: uppercase;
      color: #ffffff;
      margin-bottom: 0px;
      font-size: 24px;
      font-weight: bold; }
    #culture-grid .title p {
      color: #ffffff;
      line-height: 21px; }
    @media screen and (max-width: 39.9375em) {
      #culture-grid .title h3 {
        font-size: 18px; }
      #culture-grid .title p {
        font-size: 16px; } }
  @media screen and (max-width: 63.9375em) {
    #culture-grid {
      padding-left: 20px;
      padding-right: 20px; } }
  @media screen and (max-width: 39.9375em) {
    #culture-grid .grid {
      min-height: 260px; }
    #culture-grid .grid .copy {
      font-size: 14px;
      line-height: 26px; } }

#benefits {
  padding-top: 180px;
  padding-bottom: 100px;
  padding-left: 20px;
  padding-right: 20px; }
  #benefits .benefits {
    margin-left: auto;
    margin-top: auto; }
  #benefits .benefit {
    color: #824199;
    display: block;
    border: none;
    font-weight: bold;
    margin-bottom: 60px;
    outline: 0;
    width: max-content;
    text-transform: none;
    margin-top: 60px;
    line-height: 38px;
    -webkit-transition: text-transform 0.6s ease-in-out;
    -moz-transition: text-transform 0.6s ease-in-out;
    -o-transition: text-transform 0.6s ease-in-out;
    transition: text-transform 0.6s ease-in-out; }
    #benefits .benefit img {
      margin-right: 20px;
      width: 38px; }
  @media screen and (max-width: 63.9375em) {
    #benefits {
      padding-top: 40px; } }
  @media (max-width: 920px) {
    #benefits .benefits .columns {
      width: 100%; }
    #benefits .benefits .benefit {
      margin-bottom: 0px; }
      #benefits .benefits .benefit img {
        margin-right: 10px; } }
  @media (max-width: 400px) {
    #benefits .benefit {
      font-size: 14px; }
      #benefits .benefit img {
        display: block;
        margin-bottom: 8px; } }

#spotlight {
  background-image: url(../img/careers/jobs-spotlight-bg.jpg);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 150px 0;
  z-index: -2; }
  #spotlight h2 {
    font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    text-align: center;
    line-height: 72px; }
    #spotlight h2 span {
      font-weight: bold; }
  #spotlight .top {
    max-width: 90%;
    margin: 0 auto; }
    #spotlight .top .button {
      display: block;
      margin: 0 auto;
      max-width: 380px;
      padding: 20px 1em;
      font-size: 18px;
      border-radius: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      right: 0; }
  #spotlight .cta {
    position: relative; }
  #spotlight .featured-jobs {
    margin-top: 50px;
    max-width: 1020px;
    width: 100%; }
    #spotlight .featured-jobs .title {
      display: table;
      padding: 5px 20px;
      border-left: 1px solid #3A3A3A;
      border-right: 1px solid #3A3A3A;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      width: 100%;
      cursor: pointer; }
      #spotlight .featured-jobs .title h3 {
        text-transform: uppercase;
        color: #3A3A3A;
        margin-bottom: 0px;
        font-size: 24px;
        position: relative;
        font-weight: 500; }
      #spotlight .featured-jobs .title p {
        color: #3A3A3A;
        line-height: 21px;
        margin-bottom: 0px; }
    #spotlight .featured-jobs .job {
      position: relative;
      display: block;
      margin-bottom: 40px;
      width: 100%;
      max-width: 450px;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
      #spotlight .featured-jobs .job:hover {
        color: #824199; }
      #spotlight .featured-jobs .job .description {
        display: none;
        position: absolute;
        margin-top: 8px;
        padding: 20px 30px;
        z-index: 5;
        font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
        font-style: normal;
        font-weight: 400;
        background: #FAE46C;
        color: #3A3A3A;
        font-size: 14px;
        line-height: 26px; }
      #spotlight .featured-jobs .job.active .description {
        display: block; }
    #spotlight .featured-jobs .job.active::before {
      content: '';
      background: #FAE46C;
      position: absolute;
      width: 0px;
      height: 36px;
      z-index: -1;
      left: -20px; }
    #spotlight .featured-jobs .job.active::before {
      width: 103%; }
  @media screen and (max-width: 63.9375em) {
    #spotlight .featured-jobs {
      margin-top: 100px; }
      #spotlight .featured-jobs .job {
        margin-left: auto;
        margin-right: auto; } }
  @media screen and (max-width: 39.9375em) {
    #spotlight .featured-jobs {
      padding-left: 10px;
      padding-right: 10px; }
    #spotlight .featured-jobs .title h3 {
      font-size: 16px; }
    #spotlight .featured-jobs .title p {
      font-size: 13px; }
    #spotlight .featured-jobs .job .description {
      font-size: 12px; }
    #spotlight h2 {
      font-size: 24px;
      line-height: 38px; }
    #spotlight .top .button {
      margin-top: 30px;
      font-size: 14px; } }

.hero.search {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right top; }
  .hero.search .hero-animate {
    background-image: url("../img/search/hero.jpg"); }
  @media screen and (max-width: 39.9375em) {
    .hero.search {
      background-position: 70%; } }

#search-results {
  padding-bottom: 100px;
  padding-left: 20px;
  padding-right: 20px; }

.wrapper.search {
  padding-left: 20px;
  padding-right: 20px; }

.search h2 {
  font-size: 24px;
  color: #824199;
  text-transform: uppercase;
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 1px;
  margin-bottom: 20px; }

.search p.head-copy {
  color: #727171;
  font-size: 14px;
  line-height: 24px; }

#filters {
  padding-top: 30px; }
  #filters .filter {
    text-align: center;
    margin-bottom: 30px;
    padding: 10px;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    position: relative;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
    #filters .filter::before {
      height: 0%;
      content: ' ';
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: -1;
      -webkit-transition: height 0.5s ease-in-out;
      -moz-transition: height 0.5s ease-in-out;
      -o-transition: height 0.5s ease-in-out;
      transition: height 0.5s ease-in-out; }
    #filters .filter.entry-level {
      border-bottom: 5px solid #824199; }
      #filters .filter.entry-level::before {
        background: #824199; }
    #filters .filter.mid-level {
      border-bottom: 5px solid #643275; }
      #filters .filter.mid-level::before {
        background: #643275; }
    #filters .filter.recruit {
      color: #727171; }
      #filters .filter.recruit.active {
        color: #ffffff; }
    #filters .filter.active::before {
      height: 100%; }
    #filters .filter.software {
      border-bottom: 5px solid #FAE46C; }
      #filters .filter.software::before {
        background: #FAE46C; }
    #filters .filter.infrastructure {
      border-bottom: 5px solid #FAE46C; }
      #filters .filter.infrastructure::before {
        background: #FAE46C; }
    #filters .filter.project-management {
      border-bottom: 5px solid #8AD1D3; }
      #filters .filter.project-management::before {
        background: #8AD1D3; }
    #filters .filter.trading {
      border-bottom: 5px solid #FCA150; }
      #filters .filter.trading::before {
        background: #FCA150; }
    #filters .filter.job-category {
      color: #727171; }
    #filters .filter:hover {
      opacity: .7; }

#job-results {
  clear: both; }
  #job-results .job {
    margin-top: 30px;
    margin-bottom: 40px;
    cursor: pointer; }
    #job-results .job:hover {
      opacity: .8; }
    #job-results .job h3 {
      font-size: 24px;
      text-transform: uppercase;
      color: #3A3A3A; }
    #job-results .job .description {
      color: #727171;
      margin-top: 20px;
      font-size: 12px;
      line-height: 24px;
      display: none;
      -webkit-transition: display 0.3s ease-in-out;
      -moz-transition: display 0.3s ease-in-out;
      -o-transition: display 0.3s ease-in-out;
      transition: display 0.3s ease-in-out; }
      #job-results .job .description.active {
        display: block; }
      #job-results .job .description a.read-more {
        display: block;
        text-transform: uppercase;
        margin-top: 5px; }
    #job-results .job .title {
      padding-top: 5px; }
    #job-results .job .title, #job-results .job .description {
      padding-left: 20px;
      padding-right: 20px; }
    #job-results .job .job-meta span {
      color: #727171;
      font-size: 14px; }
    #job-results .job.software .title {
      border-left: 2px solid #FAE46C;
      border-right: 2px solid #FAE46C; }
    #job-results .job.software .job-meta i {
      color: #FAE46C; }
    #job-results .job.infrastructure .title {
      border-left: 2px solid #FAE46C;
      border-right: 2px solid #FAE46C; }
    #job-results .job.infrastructure .job-meta i {
      color: #FAE46C; }
    #job-results .job.project-management .title {
      border-left: 2px solid #8AD1D3;
      border-right: 2px solid #8AD1D3; }
    #job-results .job.project-management .job-meta i {
      color: #8AD1D3; }
    #job-results .job.trading .title {
      border-left: 2px solid #FCA150;
      border-right: 2px solid #FCA150; }
    #job-results .job.trading .job-meta i {
      color: #FCA150; }
    #job-results .job.entry-level .title {
      border-left: 2px solid #824199;
      border-right: 2px solid #824199; }
    #job-results .job.entry-level .job-meta i {
      color: #824199; }
    #job-results .job.mid-level .title {
      border-left: 2px solid #643275;
      border-right: 2px solid #643275; }
    #job-results .job.mid-level .job-meta i {
      color: #643275; }
  #job-results .job-column {
    margin: 0 auto;
    float: none; }

footer {
  background: #f7f7f7;
  padding: 90px 45px 80px 45px;
  color: #727171;
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif; }
  footer .center-container {
    position: relative; }
    footer .center-container .center {
      position: relative;
      top: 0;
      transform: none; }
  footer #footer-logo {
    max-width: 288px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
  footer .footer-wrapper {
    padding: 0px; }
  footer #locations-row {
    max-width: 430px;
    margin: 0 auto;
    display: block;
    position: relative; }
  footer .city {
    color: #824199;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 500; }
  footer .address {
    font-size: 14px; }
    footer .address:before {
      content: 'A';
      color: #824199;
      font-size: 14px;
      font-weight: 500;
      padding-right: 10px; }
    footer .address span {
      display: block;
      padding-left: 20px; }
  footer .phone {
    font-size: 14px; }
    footer .phone:before {
      content: 'P';
      color: #824199;
      font-size: 14px;
      font-weight: 500;
      padding-right: 10px; }
    footer .phone span {
      display: block;
      padding-left: 20px; }
  footer .trademark {
    font-size: 14px;
    margin-top: 30px;
    text-align: center; }
  footer .columns {
    position: relative; }
  footer .contact-us a.button {
    max-width: 240px;
    margin: 0 auto; }
  footer .top .columns, footer .bottom .columns {
    padding: 0px; }
  footer .top {
    padding-bottom: 30px; }
  footer .bottom {
    border-top: 1px solid #CFD1D7;
    padding-top: 40px; }
    footer .bottom ul {
      margin: 0px; }
      footer .bottom ul li {
        list-style: none;
        padding-bottom: 5px;
        font-size: 14px;
        font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
        font-style: normal;
        font-weight: 400; }
        footer .bottom ul li a {
          color: #727171;
          -webkit-transition: color 0.3s ease-in-out;
          -moz-transition: color 0.3s ease-in-out;
          -o-transition: color 0.3s ease-in-out;
          transition: color 0.3s ease-in-out; }
          footer .bottom ul li a:hover {
            color: #824199; }
      footer .bottom ul li.title {
        margin-bottom: 15px;
        text-transform: uppercase; }
        footer .bottom ul li.title a {
          color: #824199;
          -webkit-transition: color 0.3s ease-in-out;
          -moz-transition: color 0.3s ease-in-out;
          -o-transition: color 0.3s ease-in-out;
          transition: color 0.3s ease-in-out; }
          footer .bottom ul li.title a:hover {
            color: #643275; }
  footer .social .content {
    display: inline-block;
    float: right; }
  footer .social p {
    color: #824199;
    font-size: 14px;
    font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-style: normal;
    font-weight: 400; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    footer #footer-logo {
      padding-top: 50px; } }
  @media screen and (max-width: 63.9375em) {
    footer {
      padding: 20px 20px 70px 20px; }
      footer #footer-logo {
        margin-bottom: 40px; }
      footer .contact-us .content {
        position: relative;
        display: inline-block;
        float: right; }
      footer #locations-row {
        max-width: 430px;
        margin: 0;
        display: block;
        position: relative; }
      footer .address,
      footer .phone,
      footer .trademark {
        font-size: 13px; } }
  @media (max-width: 920px) {
    footer #footer-logo {
      position: relative;
      top: 0;
      -webkit-transform: none;
      -ms-transform: none;
      transform: none; } }
  @media screen and (max-width: 39.9375em) {
    footer {
      padding: 80px 20px 30px 20px; }
      footer #footer-logo {
        margin-bottom: 10px;
        position: relative;
        top: 0;
        transform: none;
        max-width: 240px; }
      footer #locations-row,
      footer .contact-us .content {
        display: none; }
      footer .bottom ul li {
        display: none; }
        footer .bottom ul li.title {
          display: block; } }

.social-button {
  height: 32px;
  width: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 50%;
  background-color: #979797;
  display: inline-block;
  margin-left: 5px;
  -webkit-transition: background-color 0.3s ease-in-out;
  -moz-transition: background-color 0.3s ease-in-out;
  -o-transition: background-color 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out; }
  .social-button:hover {
    background-color: #824199; }
  .social-button:first-child {
    margin-left: 0px; }
  .social-button i {
    color: #f7f7f7; }

.no-pad {
  padding: 0px; }

.wrapper {
  padding-top: 80px;
  padding-bottom: 80px; }

.large-row {
  max-width: 80rem; }

.button {
  display: block;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 500;
  max-width: 280px;
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  letter-spacing: 1px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #824199; }
  .button:hover {
    background-color: #643275; }
  .button.outline {
    background-color: transparent;
    border: 1px solid #824199;
    color: #824199; }
    .button.outline:hover {
      background-color: #824199;
      color: #ffffff; }
  .button.gradient {
    background: -webkit-gradient(linear, right bottom, left bottom, color-stop(0.32, #8F4DA7), color-stop(0.65, #713D9F)) !important;
    background: -o-linear-gradient(left, #8F4DA7 32%, #713D9F 65%) !important;
    background: -moz-linear-gradient(left, #8F4DA7 32%, #713D9F 65%) !important;
    background: -webkit-linear-gradient(left, #8F4DA7 32%, #713D9F 65%) !important;
    background: -ms-linear-gradient(left, #8F4DA7 32%, #713D9F 65%) !important;
    background: linear-gradient(to left, #8F4DA7 32%, #713D9F 65%) !important; }
    .button.gradient:hover {
      background: #643275; }

.bottom-v {
  bottom: 0px;
  position: absolute; }

.center-h {
  left: 0;
  right: 0;
  marign: 0 auto; }

.align-right {
  right: 0;
  margin-left: auto;
  margin-right: 0; }

.typed-cursor {
  color: #ffffff;
  opacity: 1;
  font-size: 1.5rem;
  display: none;
  /*
	-webkit-animation: blink 0.7s infinite;
	-moz-animation: blink 0.7s infinite;
	animation: blink 0.7s infinite;
	*/ }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .typed-cursor {
      font-size: 48px; } }
  @media screen and (min-width: 64em) {
    .typed-cursor {
      font-size: 72px; } }

header {
  position: absolute;
  z-index: 99;
  top: 2.5rem;
  width: 100%; }
  header img {
    width: 8.75rem; }

.nav {
  z-index: 999;
  display: block;
  position: fixed;
  transform: rotate(90deg);
  transform-origin: 0% 0%;
  height: 142px;
  padding-bottom: 39px;
  background: rgba(151, 151, 151, 0.75);
  width: 100vh;
  margin-left: 38px; }
  .nav a {
    color: #FFFFFF;
    font-weight: 500;
    font-size: 0.9375rem;
    text-transform: uppercase;
    display: inline-block;
    white-space: nowrap;
    position: relative;
    transition: all ease 0.3s;
    line-height: 38px;
    float: left;
    width: 25vh;
    text-align: center; }
    .nav a:hover .circle {
      opacity: 1; }
    .nav a:hover span {
      color: #FFFFFF; }
    .nav a .circle {
      height: 131px;
      width: 102%;
      position: absolute;
      background: #824199;
      display: block;
      top: 0;
      z-index: 1;
      left: 1px;
      opacity: 0;
      transition: all ease 0.3s; }
    .nav a span {
      position: relative;
      z-index: 2;
      padding: 0 30px; }
    .nav a.active .circle {
      opacity: 1; }
    .nav a.active span {
      color: #FFFFFF; }

.mobile-nav {
  background: #643275;
  position: sticky;
  top: 0;
  z-index: 9999;
  padding: 1.25rem 0 0; }
  .mobile-nav #menu-trigger {
    float: left;
    color: #FFFFFF;
    font-size: 25px;
    transition: all ease 0.3s; }
    .mobile-nav #menu-trigger.rotate {
      transform: rotate(90deg); }
  .mobile-nav img {
    float: right;
    width: 80px; }
  .mobile-nav .extended {
    max-height: 0;
    transition: all ease 0.3s;
    padding-top: 0.625rem;
    overflow: hidden; }
    .mobile-nav .extended.active {
      max-height: 1000px; }
    .mobile-nav .extended a {
      width: 100%;
      transition: all ease 0.3s;
      float: left;
      text-align: center;
      display: inline-block;
      color: #FFFFFF;
      padding: 1.25rem 0; }
      @media screen and (min-width: 40em) and (max-width: 63.9375em) {
        .mobile-nav .extended a {
          width: 50%; } }
      .mobile-nav .extended a:hover {
        opacity: 0.7; }

.hero-animate {
  position: absolute;
  transition: all .3s ease;
  animation: zoomOutAnimation 5s;
  -webkit-animation: zoomOutAnimation 5s;
  /* Safari 4+ */
  -moz-animation: zoomOutAnimation 5s;
  /* Fx 5+ */
  -o-animation: zoomOutAnimation 5s;
  /* Opera 12+ */
  animation: zoomOutAnimation 5s;
  /* IE 10+, Fx 29+ */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%; }

.hero {
  height: 15.625rem;
  position: relative;
  overflow: hidden;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  top: 0; }
  @media screen and (min-width: 40em) {
    .hero {
      min-height: 600px; } }
  .hero img.logo {
    width: 8.75rem;
    position: absolute;
    top: 60px;
    right: 70px;
    display: block; }
    @media screen and (max-width: 39.9375em) {
      .hero img.logo {
        width: 120px;
        position: absolute;
        top: 60px;
        right: 30px;
        display: block; } }
  .hero .content {
    left: 0;
    right: 0;
    padding-left: 20px;
    padding-right: 20px; }
    .hero .content h1 {
      text-transform: uppercase;
      font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
      line-height: 85px;
      margin-bottom: 20px;
      font-size: 72px;
      font-weight: bold;
      color: #ffffff; }
      .hero .content h1.hero-headline {
        display: inline; }
    .hero .content p {
      color: #ffffff;
      text-transform: uppercase;
      font-size: 15px;
      font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
      font-style: normal;
      font-weight: 500; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .hero .content h1, .hero .content .typed-cursor {
      font-size: 48px;
      line-height: 68px; } }
  @media screen and (max-width: 39.9375em) {
    .hero .content h1, .hero .content .typed-cursor {
      font-size: 38px;
      line-height: 52px; }
    .hero .content p {
      font-size: 14px;
      margin-top: 10px; } }
  @media (max-width: 400px) {
    .hero .hero-search {
      height: 45px; }
      .hero .hero-search input[type=text] {
        height: 44px;
        margin-top: -2px; }
    .hero .content {
      padding-left: 10px;
      padding-right: 10px; }
      .hero .content h1, .hero .content .typed-cursor {
        font-size: 28px;
        line-height: 38px; }
      .hero .content p {
        font-size: 14px;
        margin-top: 10px; } }

p {
  font-weight: 400;
  letter-spacing: 0.04688rem;
  line-height: 2.25rem; }

h2 {
  color: #824199;
  font-weight: 200;
  letter-spacing: 0.35938rem;
  line-height: 1;
  margin-bottom: 2.5rem; }

.wow {
  visibility: hidden; }

.body-container {
  position: relative; }

.internal-nav {
  float: right;
  margin-right: 3.75rem;
  margin-top: 2.5rem;
  padding-top: 1.25rem;
  width: 12.5rem;
  z-index: 9999;
  left: unset !important;
  display: none; }
  .internal-nav a {
    display: block;
    text-align: left;
    width: 100%;
    display: block;
    padding: 0.3125rem 0 0.3125rem 2.8125rem;
    font-size: 0.875rem;
    color: #979797;
    line-height: 1.625rem;
    transition: all ease 0.3s; }
    .internal-nav a:hover {
      opacity: 0.7; }
    .internal-nav a span {
      font-weight: 500;
      margin-right: 0.625rem;
      font-size: 0.9375rem;
      letter-spacing: 0.15625rem;
      line-height: 0.9375rem; }
    .internal-nav a.active {
      border-bottom: 1px solid #824199;
      color: #824199; }
      .internal-nav a.active:hover {
        opacity: 1; }
  .internal-nav .midnightHeader.default a {
    color: #979797; }
    .internal-nav .midnightHeader.default a.active {
      border-bottom: 1px solid #824199;
      color: #824199; }
  .internal-nav .midnightHeader.white a {
    color: #FFFFFF; }
    .internal-nav .midnightHeader.white a.active {
      border-bottom: 1px solid #8AD1D3;
      color: #8AD1D3; }

body.is-reveal-open {
  overflow: visible; }

.reveal-overlay {
  background-color: rgba(0, 0, 0, 0.7);
  overflow-y: hidden; }

.reveal.video {
  position: relative;
  padding: 0px;
  background: transparent;
  border: none;
  box-shadow: none; }
  .reveal.video iframe, .reveal.video video {
    margin-top: 30px;
    width: 100%;
    height: 100%;
    min-height: 430px; }

.close-button {
  text-transform: uppercase;
  font-size: 16px;
  color: #ffffff;
  position: absolute;
  right: 0;
  -webkit-transition: color 0.3s ease-in-out;
  -moz-transition: color 0.3s ease-in-out;
  -o-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out; }
  .close-button:hover {
    color: #EEEEEE; }

.teal-bg {
  background-color: #8AD1D3; }

.yellow-bg {
  background-color: #FAE46C; }

.orange-bg {
  background-color: #FCA150; }

.tooltip {
  max-width: 400px;
  min-width: 400px;
  border: 1px solid #824199;
  border-top: 5px solid #824199;
  background: #ffffff;
  color: #727171;
  padding: 20px 30px;
  font-size: 14px; }
  .tooltip:before {
    width: 100%;
    height: 2px;
    background: #824199;
    marign-top: 2px; }
  .tooltip.bottom::before {
    content: '';
    border: none; }

.hero.terms {
  background-color: #452351; }

.terms-and-conditions p {
  color: #979797;
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-style: normal;
  font-weight: 400; }
