.listing.hero{
  .hero-animate{
    background-image: url('../img/listing/hero.jpg');
  }
  &.software{
    .hero-animate{
      background-image: url('../img/listing/software-hero.jpg');
    }
  }
  &.other{
    .hero-animate{
      background-image: url('../img/listing/other-hero.jpg');
    }
  }
  &.quant{
    .hero-animate{
      background-image: url('../img/listing/quant-hero.jpg');
    }
  }
  &.trading{
    .hero-animate{
      background-image: url('../img/listing/trading-hero.jpg');
    }
  }
  &.infra{
    .hero-animate{
      background-image: url('../img/listing/infra-hero.jpg');
    }
  }
  @include cover_bg();
  background-position: right top;
  @include breakpoint(medium down){
    .job-meta{
      span{
        font-size: 16px;
        padding-right: 10px;
      }
    }
  }
  @include breakpoint(small down){
    background-position: 70%;
  }
}
.wrapper.listing{
  padding-left:20px;
  padding-right:20px;
  @include breakpoint(small down){
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
.listing{
  position: relative;
  .main{
    float:right;
    z-index: 2;
  }
  .side{
    float:right;
    position: relative;
    z-index: 2;
  }
  .side-nav{
    max-width: 200px;
    margin-left:auto;
    margin-right:0;
    .apply{
      font-size: 18px;
      line-height: 22px;
    }
  }
  @include breakpoint(medium down){
    .side-nav{
      max-width: 100%;
      margin-bottom: 50px;
      clear: both;
      float: none;
      .apply{
        font-size: 16px;
      }
      .button{
        max-width: 100%;
        display: block;
      }
    }
  }
  @include breakpoint(small down){
    .side-nav{
      .apply{
        font-size: 16px;
      }
    }
  }
  .section{
    margin-bottom: 70px;
    h2{
      color: $purple;
      font-size: 24px;
      margin-bottom: 20px;
      text-transform: uppercase;
      @include gotham-medium();
    }
    color: $copy-gray;
    @include gotham-book();
    p{
      color: $copy-gray;
    }
    .copy{
      padding-left:80px;
      font-size: 18px;
    }
    @include breakpoint(medium down){
      h2{
        font-size: 20px;
      }
      .copy{
        padding-left: 30px;
        font-size: 16px;
        .copy p{
          font-size: 16px;
        }
      }
    }
    @include breakpoint(small down){
      h2{
        font-size: 16px;
        line-height: 24px;
      }
      .copy{
        padding-left: 20px;
        font-size: 14px;
        line-height: 26px;
        p{
          line-height: 26px;
        }
      }
    }
  }
}
.job-meta{
  span{
    color: #ffffff;
    padding-right:30px;
    font-size: 18px;
    display: inline-block;
    &:last-child{
      padding-right: 0px;
    }
  }
}

.ctc-callout{
  background: transparent;
  position: relative;
  color: $purple;
  opacity: .2;
  font-weight: bold;
  font-family: $gotham;
  text-transform: uppercase;
  font-size: 72px;
  text-align: right;
  position: absolute;
  bottom:-30px;
  right:0px;
  letter-spacing: 6px;
  line-height: 76px;
  padding-right:20px;
  z-index: 1;
  display: none;
  @include breakpoint(medium down){
    font-size: 54px;
    line-height: 56px;
  }
  @include breakpoint(small down){
    font-size: 38px;
    line-height: 42px;
    position: relative;
    bottom:0px;
  }
  @media(max-width:400px){
    font-size: 28px;
    line-height: 32px;
    margin: 0 auto;
    left:0;
    right:0;
    padding:0;
  }
}
.footer-wrapper{
  .medium-8{
    .row{
      .columns:last-child{
        ul li a{
          text-transform: uppercase;
          color: $purple;
        }
      }
    }
  }
}