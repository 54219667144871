//
// CTC Interim Variables
// --------------------------------------------------
$purple: #824199;
$dark-purple: darken(#824199, 10%);
$gray-light: #D8D8D8;
$background-light-gray: #f7f7f7;
$gray: #727171;
$black: #3A3A3A;
$white: #FFFFFF;
$teal: #8AD1D3;
$yellow: #FAE46C;
$orange: #FCA150;
$gray-medium: #CFD1D7;
$copy-gray: #979797;

//search color variables

$software_color: $yellow;
$infra_color: $yellow;
$pm_color: $teal;
$trade_color: $orange;
$entry_color: $purple;
$mid_color: $dark-purple;

// CTC Interim Fonts
$helvetica: 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
$gotham: "Gotham SSm A", "Gotham SSm B", $helvetica;

@mixin gotham-book(){
  font-family: $gotham;
  font-style: normal;
  font-weight: 400;
}
@mixin gotham-medium(){
  font-family: $gotham;
  font-style: normal;
  font-weight: 500;
}

.gotham-extra-light {
  font-family: $gotham;
  font-style: normal;
  font-weight: 200;
}
.gotham-extra-light-italic {
  font-family: $gotham;
  font-style: italic;
  font-weight: 200;
}
.gotham-light {
  font-family: $gotham;
  font-style: normal;
  font-weight: 300;
}
.gotham-light-italic {
  font-family: $gotham;
  font-style: italic;
  font-weight: 300;
}
.gotham-book {
  font-family: $gotham;
  font-style: normal;
  font-weight: 400;
}
.gotham-book-italic {
  font-family: $gotham;
  font-style: italic;
  font-weight: 400;
}
.gotham-medium {
  font-family: $gotham;
  font-style: normal;
  font-weight: 500;
}
.gotham-medium-italic {
  font-family: $gotham;
  font-style: italic;
  font-weight: 500;
}
.gotham-bold {
  font-family: $gotham;
  font-style: normal;
  font-weight: 700;
}
.gotham-italic {
  font-family: $gotham;
  font-style: italic;
  font-weight: 700;
}
.gotham-black {
  font-family: $gotham;
  font-style: normal;
  font-weight: 800;
}
.gotham-black {
  font-family: $gotham;
  font-style: italic;
  font-weight: 800;
}

p {
	font-weight: 400;
}

//Transitions
@mixin base_transition($selector, $duration){
  -webkit-transition: $selector $duration ease-in-out;
  -moz-transition: $selector $duration ease-in-out;
  -o-transition: $selector $duration ease-in-out;
  transition: $selector $duration ease-in-out;
}
@mixin gradient($start, $end){
  background: -webkit-gradient(
                  linear,
                  right bottom,
                  left bottom,
                  color-stop(0.32, $start),
                  color-stop(0.65, $end)
  ) !important;
  background: -o-linear-gradient(left, $start 32%, $end 65%) !important;
  background: -moz-linear-gradient(left,$start 32%, $end 65%) !important;
  background: -webkit-linear-gradient(left, $start 32%, $end 65%) !important;
  background: -ms-linear-gradient(left, $start 32%, $end 65%) !important;
  background: linear-gradient(to left, $start 32%, $end 65%) !important;
}

//Animations
@keyframes blink {
  50% {
    opacity: 0.0;
  }
}
@-webkit-keyframes blink {
  50% {
    opacity: 0.0;
  }
}

//Styles
@mixin cover_bg(){
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
}
@mixin contain_bg(){
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
}

@mixin outline_title($color){
  display: table;
  padding:5px 20px;
  border-left:1px solid $color;
  border-right:1px solid $color;
  @include base_transition(all, .3s);
  width: 100%;
  h3{
    text-transform: uppercase;
    color: $color;
    margin-bottom: 0px;
    font-size: 24px;
    position: relative;
    font-weight: 500;
  }
  p{
    color: $color;
    line-height: 21px;
    margin-bottom: 0px;
  }
}



@keyframes zoomOutAnimation {
  0%  { transform: scale(1.2); }
  100% { transform: scale(1); }
}
@-webkit-keyframes NAME-YOUR-ANIMATION {
  0%  { transform: scale(1.2); }
  100% { transform: scale(1); }
}
@-moz-keyframes NAME-YOUR-ANIMATION {
  0%  { transform: scale(1.2); }
  100% { transform: scale(1); }
}
@-o-keyframes NAME-YOUR-ANIMATION {
  0%  { transform: scale(1.2); }
  100% { transform: scale(1); }
}
