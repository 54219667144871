.hero.about {
	.hero-animate{
		background: url('../img/about/whoweare-hero-1-2200-over20-tiny.jpg') center center/cover no-repeat $dark-purple;
	}
}
.color-fix{
	width:100%;
	height: 300px;
	background: #ddf1f3;
	position: absolute;
}
#subject{
	background-color: #EEEEEE;
	border: 1px solid #EEEEEE;
}
.about--intro {
	padding: rem-calc(60) 0;
	position: relative;
	.stock--container {
		margin: rem-calc(100) auto;
		p {
			margin-bottom: 0;
		}
	}
	.line-image {
		width: 50%;
		position: relative;
		margin-top: rem-calc(-180);
		z-index: 999;
	}
	.stock-name {
		text-transform: uppercase;
		font-size: rem-calc(15);
		font-weight: 500;
		letter-spacing: rem-calc(2.5);
		display: inline;
	}
	.stock-percentage { 
		display: inline;
		float: right;
		color: $gray;
		font-size: rem-calc(14);
	}
	.stock-price {
		color: $gray;
		font-weight: 200;
		font-size: rem-calc(36);
		float: right;
	}
	i {
		font-size: rem-calc(36);
	}
	hr {
		margin: rem-calc(10) 0;
	}
	.purple {
		.stock-name {
			color: $purple;
		}
		hr {
			border-color: $purple;
		}
		i {
			color: $purple;
		}
	}
	.yellow {
		.stock-name {
			color: $yellow;
		}
		hr {
			border-color: $yellow;
		}
		i {
			color: $yellow;
		}
	}
	.orange {
		.stock-name {
			color: $orange;
		}
		hr {
			border-color: $orange;
		}
		i {
			color: $orange;
		}
	}
	.teal {
		.stock-name {
			color: $teal;
		}
		hr {
			border-color: $teal;
		}
		i {
			color: $teal;
		}
	}
	.graph {
		position: absolute;
		bottom: 0;
		width:100%;
	}
}

.about--locations {
	.location--container {
		height: rem-calc(350);
		@include breakpoint(medium up) {
			height: 500px;
		}
		position: relative;
		overflow: hidden;
		&:hover {
			.inner {
				opacity: 1;
			}
		}
	}
	video {
		position: absolute;
	    top: 0;
	    width: 100%;
	    height: 100%;
	    position: absolute;
	    left: 50%; /* % of surrounding element */
	    top: 50%;
	    transform: translate(-50%, -50%); /* % of current element */
	    height: 100%;
	    width: 80.77777778vh; /* 100 * 16 / 9 */
	    min-width: 100%;
	    min-height: 56.25vw; /* 100 * 9 / 16 */
	}
	.location-date, .location-time {
		font-size: rem-calc(72);
		color: $white;
		font-weight: 700;
		margin-bottom: 0;
		line-height: 1;
	}
	.location-date {
		opacity: 0.6;
	}
	.inner {
		background: rgba(0, 0, 0, 0.8);
		position: absolute;
		height: 100%;
		width: 100%;
		opacity: 0;
		transition: all ease 0.3s;
		padding-top: 30px;
		@include breakpoint(medium up) {
			padding-top: 0;
		}

		.line {
			display: block;
			width: rem-calc(1);
			margin-left: rem-calc(40);
			&.purple {
				background: $purple;
			}
			&.long {
				height: rem-calc(110);
			}
			&.short {
				height: rem-calc(80);
			}
		}
		a {
			display: block;
			color: $white;
			font-size: rem-calc(18);
			line-height: rem-calc(30);
			margin-bottom: rem-calc(15);
			font-weight: 400;
			transition: all ease 0.3s;
			&:hover {
				color: $purple;
			}
		}
		h2 {
			line-height: 1.7;
			color: $white;
			margin-bottom: 0;
			margin-bottom: rem-calc(10);
			@include breakpoint(medium up) {
				margin-bottom: 0;
			}
		}
		.address {
			&:before {
		     	content: 'A';
			    color: $white;
			    opacity: 0.6;
			    font-weight: 500;
			    margin-right: rem-calc(10);
		    }
		    span {
			    padding-left: rem-calc(20);
		    }
		}
		.phone {
			&:before {
		     	content: 'P';
			    color: $white;
			    opacity: 0.6;
			    font-weight: 500;
			    margin-right: rem-calc(10);
		    }
		    span {
			    padding-left: rem-calc(20);
		    }
		}
		.fax {
			&:before {
		    	content: 'F';
				color: $white;
				opacity: 0.6;
				font-weight: 500;
				margin-right: rem-calc(10);
		    }
		}
		.email {
			&:before {
		    	content: 'E';
				color: $white;
		    	opacity: 0.6;
		    	font-weight: 500;
		    	margin-right: rem-calc(10);
		    }
		}
	}
}

.about--contact {
	padding: rem-calc(70) 0;
	h2 {
		margin-top: rem-calc(20);
	}
	form {
		margin-top: rem-calc(30);
	}
	label {
		text-transform: uppercase;
		color: $purple;
		font-size: rem-calc(14);
		line-height: rem-calc(26);
		font-weight: 400;
	}
	textarea {
		padding: rem-calc(15) rem-calc(15);
		margin-bottom: 0;
	}
	input {
		padding: rem-calc(5) rem-calc(15);
	}
	textarea, input {
		border: none;
		background: #EEEEEE;
		box-shadow: none;
		transition: all ease 0.3s;
		font-family: $gotham;
		font-style: normal;
		font-weight: 400;
		color: $copy-gray;
		&:focus {
			background: #EEEEEE;
			border: none;
			color: $black;
			box-shadow: none;
		}
	}
	button {
		background: $purple;
		color: $white;
		text-transform: uppercase;
		font-family: $gotham;
		font-style: normal;
		font-weight: 500;
		width: 100%;
		@include breakpoint(medium up) {
			width: 50%;
		}
		font-size: rem-calc(15);
		padding: rem-calc(10);
		letter-spacing: rem-calc(2.5);
		transition: all ease 0.3s;
		cursor: pointer;
		margin-top: rem-calc(50);
		&:hover {
			opacity: 0.7;
		}
	}
}

.about--cta {
	background: url('../img/about/whoweare-cta-banner-1-2200-over20-tiny.jpg') center center/cover no-repeat;
	padding: rem-calc(100) 0;
	text-align: center;
	.button {
		font-size: rem-calc(14)!important;
		@include breakpoint(medium up) {
			font-size: rem-calc(18)!important;
		}
		border-radius: 0;
		max-width: rem-calc(480);
		letter-spacing: rem-calc(3.41);
		font-size: rem-calc(18);
		margin: auto;
		color: $white!important;
	}
}