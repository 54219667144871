.hero.search{
  .hero-animate{
    background-image: url('../img/search/hero.jpg');
  }
  @include cover_bg();
  background-position: right top;
  @include breakpoint(small down){
    background-position: 70%;
  }
}
#search-results{
  padding-bottom: 100px;
  padding-left:20px;
  padding-right:20px;
}
.wrapper.search{
  padding-left:20px;
  padding-right:20px;
}
.search{
  h2{
    font-size: 24px;
    color: $purple;
    text-transform: uppercase;
    @include gotham-book();
    letter-spacing: 1px;
    margin-bottom: 20px;
  }
  p.head-copy{
    color: $gray;
    font-size: 14px;
    line-height: 24px;
  }
}
#filters{
  padding-top:30px;
  .filter{
    text-align: center;
    margin-bottom: 30px;
    padding:10px;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    position: relative;
    @include base_transition(all, .3s);
    &::before{
      height: 0%;
      content: ' ';
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: -1;
      @include base_transition(height, .5s);
    }
    &.entry-level{
      border-bottom: 5px solid $entry_color;
      &::before{
        background: $entry_color;
      }
    }
    &.mid-level{
      border-bottom: 5px solid $mid_color;
      &::before{
        background: $mid_color;
      }
    }
    &.recruit{
      color: $gray;
      &.active{
        color: #ffffff;
      }
    }
    &.active::before{
      height: 100%;
    }
    &.software{
      border-bottom: 5px solid $software_color;
      &::before{
        background: $software_color;
      }
    }
    &.infrastructure{
      border-bottom: 5px solid $infra_color;
      &::before{
        background: $infra_color;
      }
    }
    &.project-management{
      border-bottom: 5px solid $pm_color;
      &::before{
        background: $pm_color;
      }
    }
    &.trading{
      border-bottom: 5px solid $trade_color;
      &::before{
        background: $trade_color;
      }
    }
    &.job-category{
      color: $gray;
    }
    &:hover{
      opacity: .7;
    }
  }
}
#job-results{
  clear: both;
  .job{
    margin-top: 30px;
    margin-bottom: 40px;
    cursor: pointer;
    &:hover{
      opacity: .8;
    }
    h3{
      font-size: 24px;
      text-transform: uppercase;
      color: $black;
    }
    .description{
      color: $gray;
      margin-top: 20px;
      font-size: 12px;
      line-height: 24px;
      display: none;
      @include base_transition(display, .3s);
      &.active{
        display: block;
      }
      a.read-more{
        display: block;
        text-transform: uppercase;
        margin-top:5px;
      }
    }
    .title{
      padding-top:5px;
    }
    .title, .description{
        padding-left:20px;
        padding-right:20px;
    }
    .job-meta{
      span{
        color: $gray;
        font-size: 14px;
      }
    }
    &.software{
      .title{
        border-left:2px solid $software_color;
        border-right:2px solid $software_color;
      }
      .job-meta{
        i{
          color: $software_color;
        }
      }
    }
    &.infrastructure{
      .title{
        border-left:2px solid $infra_color;
        border-right:2px solid $infra_color;
      }
      .job-meta{
        i{
          color: $infra_color;
        }
      }
    }
    &.project-management{
      .title{
        border-left:2px solid $pm_color;
        border-right:2px solid $pm_color;
      }
      .job-meta{
        i{
          color: $pm_color;
        }
      }
    }
    &.trading{
      .title{
        border-left:2px solid $trade_color;
        border-right:2px solid $trade_color;
      }
      .job-meta{
        i{
          color: $trade_color;
        }
      }
    }
    &.entry-level{
      .title{
        border-left:2px solid $entry_color;
        border-right:2px solid $entry_color;
      }
      .job-meta{
        i{
          color: $entry_color;
        }
      }
    }
    &.mid-level{
      .title{
        border-left:2px solid $mid_color;
        border-right:2px solid $mid_color;
      }
      .job-meta{
        i{
          color: $mid_color;
        }
      }
    }
  }
  .job-column{
    margin:0 auto;
    float: none;
  }
}
